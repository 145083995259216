export default {
  // 校验身份证号
  checkIDCard(str) {
    if (!str) {
      return "身份证号不能为空!";
    }
    if (
      !/^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
        str
      )
    ) {
      return "身份证号不正确或不符合规定!";
    }
    return true;
  },
  // 校验企业名称
  checkEnterpriseName(str) {
    if (!str) {
      return "企业名称不能为空!";
    }
    return true;
  },
  // 校验企业地址
  checkEnterpriseAddress(str) {
    if (!str) {
      return "企业地址不能为空!";
    }
    return true;
  },
  // 校验社会信用代码
  checkSocialCreditCode(str) {
    if (!str) {
      return "社会统一信用代码不能为空!";
    }
    if (str.length < 17) {
      return "社会统一信用代码有误!";
    }
    // if (
    //   !/^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/.test(
    //     str
    //   )
    // ) {
    //   return "社会统一信用代码有误!";
    // }
    // 长度最少17
    return true;
  },
  // 校验企业基本开户行
  checkEnterpriseBank(str) {
    if (!str) {
      return "企业基本开户行不能为空!";
    }
    return true;
  },
  // 校验企业基本户账号
  checkSocialCreditAccount(str) {
    if (!str) {
      return "企业基本户账号不能为空!";
    }
    // if (!/^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/.test(str)) {
    //     return '企业基本户账号有误!'
    // }
    if (!/^[0-9]*$/.test(str)) {
      return "企业基本户账号有误!";
    }
    return true;
  },
  // 手机号
  checkPhone(str) {
    if (!str) {
      return "手机号不能为空";
    }
    if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(str)) {
      return "请输入正确的手机号";
    }
    return true;
  },
  // 注册密码
  checkPassword(str) {
    if (!str) {
      return "密码不能为空";
    }
    if (!((/[0-9]+/.test(str)) && (/[a-z]+/.test(str)) && (/[A-Z]+/.test(str)) && (str.length>6))) {
      return "请输入8-20位，需包含大小写字母和数字"
    }
    return true
  },
  // 验证码
  checkPhoneCode(str) {
    if (!str) {
      return '验证码不能为空'
    }
    if (!/[a-zA-Z0-9]{6}/.test(str)) {
      return "请输入正确的验证码"
    }
    return true;
  },
  // 条款勾选
  checkAgreement(str) {
    if (!str) {
      return '请您仔细阅读条款，并同意该条款后，再进行提交'
    }
    return true;
  },
  // 发票抬头
  checkInvoiceHead(str) {
    if (!str) {
      return '发票抬头不能为空'
    }
    return true;
  },
  // 发票纳税人编号
  checkInvoiceCode(str) {
    if (!str) {
      return '发票纳税人编号不能为空'
    }
    return true;
  },
  // 发票纳税人地址
  checkInvoiceAddress(str) {
    if (!str) {
      return '发票纳税人地址不能为空'
    }
    return true;
  },
  // 发票纳税人银行
  checkInvoiceBank(str) {
    if (!str) {
      return '发票纳税人银行不能为空'
    }
    return true;
  },
  // 发票纳税人账户
  checkInvoiceBankCode(str) {
    if (!str) {
      return '发票纳税人账户不能为空'
    }
    return true;
  },
  // 电子发票接受邮箱
  checkInvoiceEmail(str) {
    if (!str) {
      return '电子发票接收邮箱不能为空'
    }
    if (!/^([a-zA-Z]|[0-9])(\w)+@([a-zA-Z0-9-])+\.([a-zA-Z]{2,4})$/.test(str)) {
      return '电子发票接受邮箱格式不正确'
    }
    return true;
  },
  // 电子发票接收联系电话
  checkInvoicePhone(str) {
    if (!str) {
      return '电子发票接收联系电话不能为空'
    }
    return true;
  },
  // 电子发票接受邮箱
  checkEmail(str) {
    if (!str) {
      return '邮箱不能为空'
    }
    if (!/^([a-zA-Z]|[0-9])(\w)+@[a-zA-Z0-9-]+\.([a-zA-Z]{2,4})$/.test(str)) {
      return '邮箱格式不正确'
    }
    return true;
  },
};
