<template>
  <el-dialog title="申请发票" :visible.sync="flag" width="50%" :before-close="handleClose" @open="openDialog">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="10em" class="demo-ruleForm">
      <el-form-item label="发票种类" prop="invoiceType">
        <el-radio-group v-model="ruleForm.invoiceType" @change="invoiceTypeChange">
          <el-radio label="10">普票</el-radio>
          <el-radio label="20">专票</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="企业名称" prop="companyName" v-if="ruleForm.invoiceType === '10'">
        <el-input v-model="ruleForm.companyName" placeholder="请输入企业名称"></el-input>
      </el-form-item>
      <el-form-item label="统一社会信用代码" prop="companyCode" v-if="ruleForm.invoiceType === '10'">
        <el-input v-model="ruleForm.companyCode" placeholder="请输入统一社会信用代码"></el-input>
      </el-form-item>
      <el-form-item label="联系方式" prop="phone" v-if="ruleForm.invoiceType === '10'">
        <el-input v-model="ruleForm.phone" placeholder="请输入联系方式"></el-input>
      </el-form-item>
      <el-form-item label="基本户开户账号" prop="bankAccount" v-if="ruleForm.invoiceType === '10'">
        <el-input v-model="ruleForm.bankAccount" placeholder="请输入基本户开户账号"></el-input>
      </el-form-item>
      <el-form-item label="基本户开户银行" prop="bankName" v-if="ruleForm.invoiceType === '10'">
        <el-input v-model="ruleForm.bankName" placeholder="请输入基本户开户银行"></el-input>
      </el-form-item>
      <el-form-item label="公司注册地址" prop="companyAddress" v-if="ruleForm.invoiceType === '10'">
        <el-input v-model="ruleForm.companyAddress" placeholder="请输入公司注册地址"></el-input>
      </el-form-item>
      <el-form-item label="发票金额" prop="invoiceMoney" v-if="ruleForm.invoiceType === '10'">
        <el-input v-model="ruleForm.invoiceMoney" placeholder="请输入发票金额">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item v-show="ruleForm.invoiceType === '20'">
        <p style="color:red;">注：开具专票请您联系15669032091</p>
      </el-form-item>
      <el-form-item v-if="ruleForm.invoiceType === '10'">
        <el-button @click="handleClose()">取消</el-button>
        <el-button type="primary" @click="submitForm()">确认</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  props: ["flag", "id", "money"],
  data() {
    return {
      ruleForm: {
        invoiceType: "",
        companyName: "",
        companyCode: "",
        phone: "",
        bankAccount: "",
        bankName: "",
        companyAddress: "",
        invoiceMoney: "",
      },
      rules: {
        invoiceType: [
          { required: true, message: "请输入活动名称", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      let res = await this.$getAPI.enterMessageData();
      this.ruleForm.companyName = res.companyName;
      this.ruleForm.companyCode = res.companySocialno;
      this.ruleForm.phone = res.phone;
      this.ruleForm.bankAccount = res.account;
      this.ruleForm.bankName = res.accountBank;
      this.ruleForm.companyAddress = res.companyAddr;
    },
    openDialog() {
      this.ruleForm.invoiceMoney = this.money;
    },
    invoiceTypeChange() {
      if (this.ruleForm.invoiceType === "10") {
        this.rules = {
          invoiceType: [
            { required: true, message: "请选择发票种类", trigger: "change" },
          ],
          companyName: [
            { required: true, message: "请输入企业名称", trigger: "blur" },
          ],
          companyCode: [
            {
              required: true,
              message: "请输入统一社会信用代码",
              trigger: "blur",
            },
          ],
          phone: [
            { required: true, message: "请输入联系方式", trigger: "blur" },
          ],
          invoiceMoney: [
            { required: true, message: "请输入发票金额", trigger: "blur" },
          ],
        };
      }
      if (this.ruleForm.invoiceType === "20") {
        this.rules = {
          invoiceType: [
            { required: true, message: "请输入活动名称", trigger: "change" },
          ],
        };
      }
    },
    submitForm() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.applyInvoiceApi();
        } else {
          return false;
        }
      });
    },
    async applyInvoiceApi() {
      const res = await this.$getAPI.applyInvoiceApi({
        applyTel: this.ruleForm.phone,
        companyName: this.ruleForm.companyName,
        companySocialNo: this.ruleForm.companyCode,
        fapiaoAmt: this.ruleForm.invoiceMoney,
        processId: this.id,
        type: this.ruleForm.invoiceType,
        basicAccountBank: this.ruleForm.bankName,
        basicAccountNo: this.ruleForm.bankAccount,
        companyAddress: this.ruleForm.companyAddress,
        remark: "",
      });
      if (res.code !== 1) {
        this.$message.error(res.msg)
      } else {
        this.$message({
          type: 'success',
          message: '申请已提交'
        })
        this.$refs["ruleForm"].resetFields();
        this.$emit("close", res);
      }
    },
    resetForm() {
      this.$refs["ruleForm"].resetFields();
    },
    handleClose() {
      this.$refs["ruleForm"].resetFields();
      this.$emit("close");
    },
  },
};
</script>

<style>
</style>