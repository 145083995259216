<template>
  <div id="app">
    <Heads></Heads>
    <div id="app-body">
      <router-view></router-view>
    </div>
    <Footers></Footers>
  </div>
</template>

<script>
import Heads from "./components/TabBar.vue";
import Footers from "./components/Footers";
export default {
  components: {
    Heads,
    Footers,
  },
  name: "App",
  created() {
    if (!this.$cookies.get('yc_tk')) {
      location.href = `${process.env.VUE_APP_LOGIN_URL}/?returnUrl=${encodeURIComponent(location.href)}`
    }
  }
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  height: 100vh;
  &-body {
    position: relative;
    width: 1200px;
    margin: 0 auto;
  }
}
.el-pager,
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev {
  padding: 0 5px;
  margin: 0;
}
// .el-pager,
// .el-pagination.is-background .btn-next,
// .el-pagination.is-background .btn-next {
//   padding: 0 5px;
//   margin: 0;
// }
// .el-pagination.is-background .btn-next {
//   margin-right: 15px;
// }
// .el-pagination.is-background .btn-next,
// .el-pagination.is-background .btn-prev,
// .el-pagination.is-background .el-pager li {
//   border: 1px solid #dcdcdc;
//   box-shadow: 0px 2px 4px 0px rgba(0, 117, 230, 0.77);
//   border-radius: 4px;
//   background: #fff;
// }
</style>
