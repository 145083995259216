<template>
  <div class="collection">
    <Menu></Menu>
    <div class="flex-1 content bor bgfff">
      <!-- <div class="public-title flex flex-pack-justify">
        <strong class="p_r">我的收藏</strong>
        <span> 共{{total}}条数据 </span>
      </div> -->
      <div class="order" style="position:relative;">
        <div class="tabs flex">
          <span class="p_r" :class="{active:tabIndex === 0}" @click="tabIndex=0">投标保函项目</span>
          <span class="p_r" :class="{active:tabIndex === 1}" @click="tabIndex=1">标讯信息</span>
        </div>
        <div class="total" style="position:absolute;right:20px;top:12px;">共{{tabIndex === 0 ? total : total2}}条数据</div>
      </div>
      <div class="tab1" v-show="tabIndex === 0">
        <div class="Collection-search flex">
          <div class="Collection-search-item flex">
            <label>开标情况</label>
            <el-select v-model="tab1SearchType" placeholder="请选择" size="small" @change="optionsChange">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          </div>
          <div class="Collection-search-item flex">
            <el-input v-model="tab1SearchName" placeholder="请输入项目名称" maxlength="150" size="small"></el-input>
            <div class="searchbtn" @click="search"><img src="../assets/images/search.png" alt="">搜索</div>
          </div>
        </div>
        <div class="flex flex-pack-justify Collection-title" v-show="collectionList.length > 0">
          <div class="flex flex-align-center txt">
            <div class="xz flex flex-align-center flex-pack-center">
              <input type="checkbox" id="checkbox" @click="checkedAll"/>
            </div>
            全部
          </div>
          <a href="javascript:;" class="del" @click="deleteCollection">移除收藏</a>
        </div>
        <ul class="Collection-list" v-show="collectionList.length > 0">
          <li class="p_r flex"  v-for="(item,index) in collectionList" :key="index" @click="goInsureDetail(item)">
            <div class="xz bgfff flex flex-align-center flex-pack-center">
              <input type="checkbox" name="checkbox" class="checkbox" :value="item.tenderProjectCode" @click.stop="" @change.stop="checkCollection($event,index)"/>
            </div>
            <div class="bor flex-1 flex con">
              <div class="flex-1 text-content flex flex-align-center">
                <div class="icon flex flex-align-center flex-pack-center">
                  <img :src="item.itemIconUrl" class="di_bl" />
                </div>
                <div class="flex-1 text">
                  <div class="tit">招标项目名称：{{item.tenderProjectName}}</div>
                  <div class="">项目区域：{{item.mergerName}}</div>
                  <div class="">开标时间：{{item.bidOpenTime}}</div>
                  <div class="">保函金额：<span>{{item.businessAmount}}</span></div>
                </div>
              </div>
              <div class="flex flex-align-center state">
                <a v-if="getTime(item.bidOpenTime) === 0"
                  target="_bank"
                  href="javascript:void(0);"
                  class="flex flex-align-center flex-pack-center sq"
                  @click.stop="goApply(item.tenderProjectCode)"
                  >立即申请</a
                >
                <img v-else src="../assets/images/kai.png" />
              </div>
            </div>
          </li>
        </ul>
        <!-- 分页开始 -->
        <div class="paging" v-show="collectionList.length > 0">
        <el-pagination
          background
          layout="prev, pager, next, sizes,jumper,slot"
          :current-page="pageNum"
          :page-sizes="[10, 20, 30, 50, 100]"
          :page-size="count"
          :total="total"
          prev-text="上一页"
          next-text="下一页"
          @size-change="upCountPage"
          @current-change="upPage"
        >
          <el-button
            size="small"
            class="pagebtn"
            style="
              border: 1px solid #dcdcdc;
              box-shadow: 0px 2px 4px 0px rgb(0 117 230 / 77%);
              border-radius: 4px;
              background: #fff;
            "
            >确定</el-button
          >
        </el-pagination>
      </div>
        <!-- 分页结束 -->
        <div class="empty" v-show="collectionList.length === 0">暂无数据</div>
      </div>
      <!-- 标讯信息模块start -->
      <div class="tab2" v-show="tabIndex === 1">
        <div class="Collection-search flex">
          <div class="Collection-search-item flex">
            <label>标讯类型</label>
            <el-select v-model="tab2SearchType" placeholder="请选择" size="small" @change="optionsChange2">
            <el-option
              v-for="item in tab2Options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          </div>
          <div class="Collection-search-item flex">
            <el-input v-model="tab2SearchName" placeholder="请输入项目名称" maxlength="150" size="small"></el-input>
            <div class="searchbtn" @click="search2"><img src="../assets/images/search.png" alt="">搜索</div>
          </div>
        </div>
       
        <div class="informationList">
          <div class="informationItem head" v-show="collectionList2.length > 0">
            <div class="left"><input type="checkbox" id="checkbox2" @click.stop="checkedAll2"></div>
            <div class="center"><b>全部</b></div>
            <div class="right"><div class="btn" @click="deleteCollection">移除收藏</div></div>
          </div>
          <div class="informationItem" v-show="collectionList2.length > 0" v-for="(item, index) in collectionList2" :key="index" @click="goBidDetail(item.bid_id)">
            <div class="left"><input type="checkbox" class="checkbox2" @change="checkCollection($event,index)" @click.stop=""></div>
            <div class="center">
              <p>{{item.bid_project_name}}</p>
              <span>{{typeCodeToString(item.bid_category)}}</span>
              <span style="margin-left:60px">{{item.merger_name}}</span>
            </div>
            <div class="right"><span>{{item.release_time}}</span></div>
          </div>
          <!-- 分页开始 -->
        <div class="paging" v-show="collectionList2.length > 0">
          <el-row>
            <el-col :span="18">
              <el-pagination
                background
                layout="prev, pager, next, sizes,jumper,slot"
                :current-page="pageNum2"
                :page-sizes="[10, 20, 30, 50, 100]"
                :page-size="count2"
                :total="total2"
                prev-text="上一页"
                next-text="下一页"
                @size-change="upCountPage2"
                @current-change="upPage2"
              >
              </el-pagination>
            </el-col>
            <el-col :span="6">
              <el-button size="small">确定</el-button>
            </el-col>
          </el-row>
        </div>
        <!-- 分页结束 -->
        <div class="empty" v-show="collectionList2.length === 0">暂无数据</div>
        </div>
      </div>
      <!-- 标讯信息模块end -->
    </div>
  </div>
</template>

<script>
import Menu from '../components/Menu.vue'
import cityNameIcon from '../utils/js/cityIcon'
export default {
  name: 'collection',
  components: {
    Menu
  },
  data() {
    return {
      options: [{
          value: '',
          label: '全部'
        }, {
          value: '0',
          label: '已开标'
        }, {
          value: '1',
          label: '未开标'
      }],
      tab2Options: [{
          value: '0',
          label: '全部'
        }, {
          value: '1',
          label: '拟建项目'
        }, {
          value: '2',
          label: '招标预告'
        }, {
          value: '3',
          label: '招标公告'
        }, {
          value: '4',
          label: '招标变更'
        }, {
          value: '5',
          label: '中标通知'
        }, {
          value: '6',
          label: '开标记录'
      }],
      tab1SearchName: '',
      tab2SearchName: '',
      tab1SearchType: '',
      tab2SearchType: '',
      collectionList: [],
      collectionList2: [],
      tabIndex: 0,
      deletList: [],
      deletList2: [],
      count: 10,
      total: 0,
      pageNum: 1,
      count2: 10,
      total2: 0,
      pageNum2: 1,
    }
  },
  mounted() {
    this.getList();
    this.getBidList()
  },
  methods: {
    async getBidList() {
      let res = await this.$getAPI.showBidCollection({
        pageNo: this.pageNum2,
        pageSize: this.count2,
        bidType: this.tab2SearchType ? this.tab2SearchType : '0',
        bidName: this.tab2SearchName
      })
      if (res && res.data && res.data.code && res.data.code === 2) {
        return false;
      }
      this.collectionList2 = res.date ? res.date : []
      this.total2 = parseInt(res.total);
      this.clearcheck()
    },
    async getList() {
      let res = await this.$getAPI.showCollection({
        type: this.tabIndex? 'n' : 'p',
        projectName: this.tabIndex ? this.tab2SearchName : this.tab1SearchName,
        status: this.tab1SearchType,
        pageNum: this.pageNum,
        pageSize: this.count
      })
      
      if (res && res.data && res.data.code && res.data.code === 2) {
        return false;
      }
      console.log(res)
      this.collectionList = this.setItemIcon(res.data)
      this.total = res.total;
      // this.checkbox = [];
      this.clearcheck()
    },
    typeCodeToString(code) {
      if (!code) return ''
      let str = this.tab2Options.filter((v) => code === v.value)
      return str[0].label;
    },
    clearcheck() {
      let allBtn = document.getElementById('checkbox');        
      let input = document.getElementsByClassName('checkbox')
      this.deletList = [];
      allBtn.checked = false;
      for (let i = 0; i < input.length; i++) {
        input[i].checked = false
      }
      this.clearcheck2()
    },
    clearcheck2 () {
      let allBtn2 = document.getElementById('checkbox2');        
      let input2 = document.getElementsByClassName('checkbox2')
      this.deletList2 = [];
      allBtn2.checked = false;
      for (let i = 0; i < input2.length; i++) {
        input2[i].checked = false
      }
    },
    getTime (times) {
      let nowtime = new Date()
      let timess = new Date(times)
      return nowtime > timess ? 1 : 0
    },
    deleteCollection () {
      let checkLength = this.tabIndex ? this.deletList2.length : this.deletList.length
      if (checkLength === 0) {
        this.$alert('请先勾选要移除收藏的项目', '移除收藏', {
          confirmButtonText: '确定',
          callback: () => {}
        });
      } else {
        this.$confirm(`确定要将${checkLength}条项目移除收藏吗？`, '移除收藏', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          if (this.tabIndex) {
            this.deleteCollectionApi2()
            setTimeout(() => {
              this.pageNum2 = 1;
              this.getBidList()
            }, 500);
          } else {
            this.deleteCollectionApi()
            setTimeout(() => {
              this.pageNum = 1;
              this.getList()
            }, 500);
          }
        }).catch(() => {});
      }
    },
    async deleteCollectionApi () {
      let list  = this.deletList
      await this.$getAPI.deleFocus({lists:list.join()})
    },
    async deleteCollectionApi2 () {
      let list  =  this.deletList2 
      await this.$getAPI.removeMyCollectionById({bidmsg:list})
    },
    checkCollection (e,k) {
      console.log(this.tabIndex)
      if (!this.tabIndex) {
        let code = this.collectionList[k].tenderProjectCode
        if (e.target.checked) {
          if (this.deletList.indexOf(code) === -1) {
            this.deletList.push(code)
          }
        } else {
          let index = this.deletList.indexOf(code)
          this.deletList.splice(index,1) 
        }
      } else {
        let id = this.collectionList2[k].bid_id
        let type = this.collectionList2[k].bid_category
        console.log(id, type)
        if (e.target.checked) {
          if (!this.deletList2.some(v => v.bid_id === id)) {
            this.deletList2.push({bidid: id, bidtype: type})
          }
        } else {
          let index = this.deletList2.findIndex(v => v.bid_id)
          this.deletList2.splice(index,1) 
        }
        console.log(this.deletList2)
      }
      
    },
    optionsChange() {
      this.pageNum = 1;
      this.getList()
    },
    optionsChange2() {
      this.pageNum2 = 1;
      this.getBidList()
    },
    search() {
      this.pageNum = 1;
      this.getList()
    },
    search2() {
      this.pageNum2 = 1;
      this.getBidList()
    },
    upCountPage(count) {
      this.pageNum = 1;
      this.count = count;
      this.getList();
    },
    upPage(pageNum) {
      this.pageNum = pageNum;
      this.getList();
    },
    upCountPage2(count) {
      this.pageNum2 = 1;
      this.count2 = count;
      this.getBidList();
    },
    upPage2(pageNum) {
      this.pageNum2 = pageNum;
      this.getBidList();
    },
    checkedAll(e) {
      let input = document.getElementsByClassName('checkbox')
      for (let i = 0; i < input.length; i++) {
        input[i].checked = e.target.checked
      }
      if (e.target.checked) {
        this.deletList = [];
        this.collectionList.forEach(v => {
          this.deletList.push(v.tenderProjectCode)
        })
      } else {
        this.deletList = []
      }
    },
    checkedAll2(e) {
      let input = document.getElementsByClassName('checkbox2')
      for (let i = 0; i < input.length; i++) {
        input[i].checked = e.target.checked
      }
      if (e.target.checked) {
        this.deletList2 = [];
        this.collectionList2.forEach(v => {
          this.deletList2.push({bidid:v.bid_id,bidtype:v.bid_category})
        })
      } else {
        this.deletList2 = []
      }
    },
    goInsureDetail (item) {
      let href = `${process.env.VUE_APP_BASE_URL}/v2/html/detail.html?areaCode=${item.regionCode}&projectName=${item.tenderProjectName}&projectId=${item.tenderProjectCode}`
      window.open(href)
    },
    goApply(projectId) {
      window.open(`${process.env.VUE_APP_BASE_URL}/guaranteeCtrl/index03.do?projectId=${projectId}`)
    },
    setItemIcon (list) {
      let areaNameMapping = cityNameIcon;
      let arr = [];
      list.forEach(v => {
        v['flagMathCode']=  areaNameMapping[v.regionCode.substr(0,4) + '00'] ? areaNameMapping[v.regionCode.substr(0,4) + '00'] : areaNameMapping[v.regionCode.substr(0,2) + '0000']
        v['itemIconUrl'] = `https://img.cyc-fund.com.cn/pc_recommend/images/list/${v.flagMathCode}.png`
        arr.push(v)
      })
      return arr;
    },
    goBidDetail(id) {
      window.open(`${process.env.VUE_APP_BID_URL}/Details/${id}`)
    }
  }
};
</script>

<style lang="less" scoped>
.collection {
  margin-left: 185px;
  .paging {
    margin: 40px 20px;
  }
  .Collection-search {
    &-item {
      position: relative;
      height: 32px;
      display: flex;
      align-items: center;
      overflow: hidden;
      &:first-child {
        label {
          border-radius: 8px 0 0 8px;
        }
        /deep/ .el-input__inner {
          border-radius: 0 8px 8px 0;
          border-left: 0;
        }
      }
      &:last-child {
        margin-left: 20px;
        /deep/ .el-input__inner {
          border-radius: 8px 0 0 8px;
          border-right: 0;
        }
      }
      .searchbtn {
        width: 75px;
        height: 32px;
        background: #BA1216;
        border-radius: 0px 8px 8px 0px;
        font-size: 12px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 15px;
          height: 15px;
          margin-right: 6px;
        }
      }
      label {
        width: 81px;
        font-size: 14px;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #FFFFFF;
        height: 32px;
        line-height: 35px;
        text-align: center;
        background: #BA1216;
      }
    }
    /deep/ .el-input__inner {
        height: 32px;
        line-height: 32px;
        border: 1px solid #959595;
        overflow: hidden;
        border-radius: 0;
      }
  }
  .informationList {
    .informationItem {
      width: 100%;
      display: flex;
      align-items: center;
      height: 100px;
      padding-left: 18px;
      padding-right: 20px;
      border-bottom: 1px solid #E0E5E4;
      position: relative;
      .left {
        margin-right: 22px;
        input {
          width: 24px;
          height: 24px;
          background: rgba(106, 108, 111, 0);
          border: 1px solid #D9DFE9;
        }
      }
      .center {
        flex: 1;
        b {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #222223;
          line-height: 20px;
        }
        p {
          font-size: 14px;
          height: 40px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          margin: 0;
        }
        span {
          font-size: 14px;
          font-family: inpin heiti;
          font-weight: 400;
          color: #585858;
        }
      }
      .right {
        span {
          position: absolute;
          right: 25px;
          bottom: 23px;
        }
      }
      &.head {
        height: 51px;
        .btn {
          border: 1px solid #666666;
          box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #666666;
          line-height: 36px;
          width: 99px;
          text-align: center;
        }
      }
    }
  }
  .total {
    font-size: 14px;
    font-family: Hiragino Sans GB;
    font-weight: normal;
    color: #B10B18;
  }
}
</style>
<style lang="less">
.collection .el-input__inner {
  border: 0;
}
.el-pagination__editor.el-input .el-input__inner {
  border: 1px solid #d9d9d9;
}
.collection .empty {
  margin: 50px 0;
  text-align: center;
}
</style>