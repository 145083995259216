import Index from './views/Index'
import Collection from './views/Collection'
import History from './views/History'
import MyOrder from './views/Myorder'
import Invoice from './views/Invoice'
import EnterMassage from './views/EnterMessage'
import MyMessage from './views/MyMessage'
import SwitchId from './views/SwitchId'
import UpPassword from './views/UpPassword'
import Message from './views/Message'
// import Addresss from './views/Addresss'
// import Sign from './views/Sign'
// import Login from './views/Login'
// import Password from './views/Password'
// import Position from './views/Position'
import LoanRecords from './views/loanRecords'

export default {
    // mode: location.host.indexOf('dev') > -1 ? 'hash' : 'history',
    mode: process.env.VUE_APP_ROUTE_MODE,
    base: '/',
    routes: [
        {path: '/', component: Index},
        {path: '/collection', component: Collection},
        {path: '/history', component: History},
        {path: '/myOrder', component: MyOrder},
        {path: '/invoice', component: Invoice},
        {path: '/enterMsg', component: EnterMassage},
        {path: '/myMessage', component: MyMessage},
        {path: '/switchId', component: SwitchId},
        {path: '/upPassword', component: UpPassword},
        {path: '/message', component: Message},
        {path: '/loanRecords', component: LoanRecords},
        // {path: '/address', component: Addresss},
        // {path: '/sign', component: Sign},
        // {path: '/login', component: Login},
        // {path: '/password', component: Password},
        // {path: '/position', component: Position },
        {path: "*", component: Index}
    ]
}
