<template>
  <el-dialog title="订单详情" :visible="flag" width="50%" :before-close="handleClose" @open="openDialog">
    <div class="orderDetail">
      <el-descriptions :column="2" title="">
        <el-descriptions-item label="订单编号">{{obj.processid}}</el-descriptions-item>
        <el-descriptions-item label="项目名称">{{obj.projectName}}</el-descriptions-item>
        <el-descriptions-item label="用户名称">{{obj.userid}}</el-descriptions-item>
        <el-descriptions-item label="出函机构">{{obj.bankName}}</el-descriptions-item>
        <el-descriptions-item label="用户电话">{{obj.phone}}</el-descriptions-item>
        <el-descriptions-item label="保函金额（元）">{{obj.premium}}</el-descriptions-item>
        <el-descriptions-item label="企业名称">{{obj.companyName}}</el-descriptions-item>
        <el-descriptions-item label="保证金额（元）">{{obj.amount}}</el-descriptions-item>
        <el-descriptions-item label="订单类型">{{obj.bizid}}</el-descriptions-item>
        <el-descriptions-item label="开标日期">{{obj.bidOpenTime}}</el-descriptions-item>
      </el-descriptions>
      <el-timeline class="steps">
        <el-timeline-item v-for="(activity, index) in activities" :key="index" :type="activity.type" :size="activity.size" :timestamp="activity.time" placement="top">
          <span class="steps-state">
            {{activity.state}}
          </span>
          {{activity.describe}}
        </el-timeline-item>
      </el-timeline>
    </div>
  </el-dialog>

</template>

<script>
export default {
  name: "OrderDetail",
  props: ["flag", "processid"],
  data() {
    return {
      activities: [],
      obj: {
        amount: 0,
        bankName: "",
        bidOpenTime: "",
        bizid: "",
        companyName: "",
        jsonStr: "",
        message: "",
        phone: 0,
        premium: 0,
        processid: 0,
        projectName: "",
        userid: "",
      },
    };
  },
  methods: {
    async openDialog() {
      const res = await this.$getAPI.getOrderShowDetail({
        id: '1556933921243922432', //this.processid
      });
      if (res.code === 200) {
        this.obj = res.data;
      this.activities = JSON.parse(res.data.jsonStr)
      this.activities.forEach((v, k) => {
        this.activities[k]['type'] = "success"
      });
      this.activities[0]['type'] = 'primary'
      this.activities[0]['size'] = 'large'
      } else {
        this.$message.error(res.msg)
      }
      console.log(res)
      
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="less">
.orderDetail {
  padding: 10px 20px;
  .steps {
    margin-top: 20px;
    margin-left: 100px;
    &-state {
      position: absolute;
      left: -100px;
      top: -2px;
      text-align: right;
      width: 80px;
    }
    /deep/.el-timeline-item__node--primary,
    .el-transfer__button {
      background-color: #409eff;
    }
  }
}
</style>