<template>
  <div class="history">
    <Menu></Menu>
    <!-- 右侧主体开始 -->
    <div class="flex-1 content bor bgfff">
      <div class="public-title flex flex-pack-justify">
        <strong class="p_r">历史浏览</strong>
        <span> 共9条数据 </span>
      </div>
      <div class="Collection-search flex">
        <div class="search flex">
          <input type="text" id="" class="flex-1" value="" />
          <input type="submit" id="" name="" value="搜索" />
        </div>
        <div class="flex info">
          <span>浏览类型</span>
          <select class="flex-1">
            <option value="">拟建项目</option>
          </select>
        </div>
      </div>
      <div class="flex flex-pack-justify Collection-title">
        <div class="flex flex-align-center txt">
          <div class="xz flex flex-align-center flex-pack-center">
            <input type="checkbox" name="checkbox" id="checkbox" />
          </div>
          全部
        </div>
        <a href="" class="del">删除记录</a>
      </div>
      <ul class="Collection-list">
        <li class="p_r flex">
          <div class="xz bgfff flex flex-align-center flex-pack-center">
            <input type="checkbox" name="checkbox" id="checkbox" />
          </div>
          <div class="bor flex-1 flex con">
            <div class="flex-1 text-content flex flex-align-center">
              <div class="icon flex flex-align-center flex-pack-center">
                <img src="../assets/images/house-008.png" class="di_bl" />
              </div>
              <div class="flex-1 text">
                <div class="tit">招标项目编号：A3302010220002301301</div>
                <div class="">项目名称：擎洲测试-这是一条测试数据</div>
                <div class="">项目区域：中国,浙江省,宁波市,宁波市辖区</div>
                <div class="">类型：<span>招标公告</span></div>
              </div>
            </div>
            <div class="flex flex-align-center flex-pack-center state">
              <a
                href="javascript:void(0);"
                class="flex flex-align-center flex-pack-center sq"
                >查看</a
              >
            </div>
          </div>
        </li>
        <li class="p_r flex">
          <div class="xz bgfff flex flex-align-center flex-pack-center">
            <input type="checkbox" name="checkbox" id="checkbox" />
          </div>
          <div class="bor flex-1 flex con">
            <div class="flex-1 text-content flex flex-align-center">
              <div class="icon flex flex-align-center flex-pack-center">
                <img src="../assets/images/house-008.png" class="di_bl" />
              </div>
              <div class="flex-1 text">
                <div class="tit">招标项目编号：A3302010220002301301</div>
                <div class="">项目名称：擎洲测试-这是一条测试数据</div>
                <div class="">项目区域：中国,浙江省,宁波市,宁波市辖区</div>
                <div class="">类型：<span>招标公告</span></div>
              </div>
            </div>
            <div class="flex flex-align-center flex-pack-center state">
              <a
                href="javascript:void(0);"
                class="flex flex-align-center flex-pack-center sq"
                >查看</a
              >
            </div>
          </div>
        </li>
        <li class="p_r flex">
          <div class="xz bgfff flex flex-align-center flex-pack-center">
            <input type="checkbox" name="checkbox" id="checkbox" />
          </div>
          <div class="bor flex-1 flex con">
            <div class="flex-1 text-content flex flex-align-center">
              <div class="icon flex flex-align-center flex-pack-center">
                <img src="../assets/images/house-008.png" class="di_bl" />
              </div>
              <div class="flex-1 text">
                <div class="tit">招标项目编号：A3302010220002301301</div>
                <div class="">项目名称：擎洲测试-这是一条测试数据</div>
                <div class="">项目区域：中国,浙江省,宁波市,宁波市辖区</div>
                <div class="">类型：<span>招标公告</span></div>
              </div>
            </div>
            <div class="flex flex-align-center flex-pack-center state">
              <a
                href="javascript:void(0);"
                class="flex flex-align-center flex-pack-center sq"
                >查看</a
              >
            </div>
          </div>
        </li>
      </ul>
      <!-- 分页开始 -->
      <div class="paging">
        <el-row>
          <el-col :span="18">
            <el-pagination
              background
              layout="prev, pager, next, sizes,jumper"
              :page-sizes="[5, 10, 15]"
              :page-size="1"
              :total="500"
              prev-text="上一页"
              next-text="下一页"
            >
            </el-pagination>
          </el-col>
          <el-col :span="6">
            <el-button size="small">确定</el-button>
          </el-col>
        </el-row>
      </div>
      <!-- 分页结束 -->
    </div>
    <!-- 主体结束 -->
  </div>
</template>

<script>
import Menu from "../components/Menu.vue";
export default {
  components: {
    Menu,
  },
};
</script>

<style lang="less" scoped>
.history {
  margin-left: 185px;
  .paging {
    margin: 40px 20px;
  }
}
</style>