<template>
  <div class="message">
    <Menu :notRead="notRead"></Menu>
    <div class="flex-1 content bor bgfff">
      <div class="public-title flex flex-pack-justify">
        <strong class="p_r">消息通知</strong>
      </div>
      <div class="message-content">
        <el-collapse v-model="activeNames">
          <el-collapse-item v-for="(item, index) in list" :key="index" :name="index+1">
            <template slot="title">
              <div class="message-content-title" @click="delDot(item.ID)">
              <div class="dot" v-if="item.status === 0"></div>              
              &nbsp;&nbsp;&nbsp;&nbsp;<h3>{{item.TITLE}}</h3>
              <b>{{item.SHOWTIME}}</b>
              </div>
            </template>
            <div class="message-content-p">{{item.BODY}}</div>
          </el-collapse-item>
          
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "../components/Menu.vue";
export default {
  name: "Message",
  components: {
    Menu,
  },
  data() {
    return {
      activeNames: 0,
      list: [],
      notRead: 0
    };
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const res = await this.$getAPI.getNoitce()
      this.list = res.data.result.resultData.newslist
      this.notRead = res.data.result.resultData.total
    },
    async delDot(id) {
      await this.$getAPI.delDot({ id })
      const index = this.list.findIndex(v => v.ID === id)
      if (this.list[index].status === 0) {
        this.list[index].status = 1
        this.notRead --
      }
      
    }
  }
};
</script>

<style lang="less">
.message {
  margin-left: 185px;
  h3 {
    width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &-content {
    padding-top: 60px;
    width: 80%;
    margin: 0 auto;
    padding-bottom: 60px;
    b {
      flex: 1;
      text-align: right;
      padding-right: 30px;
      font-weight: normal;
      font-size: 12px;
    }
    &-title {
      position: relative;
      width: 100%;
      display: flex;
      .dot {
        background: #f00;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 40%;
      }
    }
    &-p {
      text-indent: 2em;
      word-break: break-word;
    }
  }
}
</style>