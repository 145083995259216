<template>
  <div class="myOrder">
    <Menu></Menu>
    <div class="flex-1 content bor bgfff">
      <div class="order">
        <div class="tabs flex">
          <!--  @click="upMyOrderTab(k)" -->
          <span class="p_r" v-for="(v, k) in myOrderTab" :key="k" :class="{ active: k === myOrderTabIndex }">{{ v }}</span>
        </div>
        <div class="myOrder-search">
          <div class="myOrder-search-item">
            <label>城市</label>
            <el-cascader :options="cityData" v-model="cityValue" :filterable="true" :props="{ checkStrictly: true }" size="small" clearable></el-cascader>
          </div>
          <div class="myOrder-search-item">
            <label>招标项目编号</label>
            <el-input class="searchId" v-model="idValue" placeholder="" size="medium"></el-input>
          </div>
          <div class="myOrder-search-item">
            <label>开标日期</label>
            <!-- <el-date-picker
              :clearable="false"
              v-model="dateValue"
              type="date"
              size="small"
              placeholder="选择日期"
            > -->
            <!-- </el-date-picker> -->
            <el-date-picker v-model="dateValue" type="daterange" range-separator="-" size="small" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <div class="myOrder-search-btn">
            <el-button type="primary" @click="searchGetOrderList">查询</el-button>
            <el-button id="reset" @click="clear">重置</el-button>
          </div>
        </div>
        <div class="warp">
          <div class="con" v-show="myOrderTabIndex === 0">
            <div class="">
              <div class="bao-tab flex flex-pack-justify">
                <div class="item te_c p_r" v-for="(v, k) in protectOrderTab" :key="k" @click="upProtectOrderTab(k)">
                  <div class="icon1">
                    <img height="30" :src="
                        k === protectOrderTabIndex ? v.iconUrlActive : v.iconUrl
                      " alt="" />
                  </div>
                  <span class="myOrderIconText" :class="{ active: k === protectOrderTabIndex }">{{ v.name }}</span>
                </div>
              </div>

              <div class="bao-warp">
                <div class="bao-con" style="display: block">
                  <div class="public-tab c-tab flex te_c">
                    <span class="flex-1" v-for="(v, k) in protectTypeTab" :key="k" :class="{ active: protectTypeTabIndex === k }" @click="upProtectTypeTab(k)">{{ v.label }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="con bor" v-show="myOrderTabIndex === 1">
            <div class="z-tab flex flex-pack-justify">
              <div class="item te_c p_r" v-for="(v, k) in loanOrderTab" :key="k" @click="upLoanOrderTab(k)">
                <div class="icon1">
                  <img height="30" :src="k === loanOrderTabIndex ? v.iconUrlActive : v.iconUrl" alt="" />
                </div>
                <span class="myOrderIconText" :class="{ active: k === loanOrderTabIndex }">
                  {{ v.name }}</span>
              </div>
            </div>
          </div>
          <el-table empty-text="无内容" height="400" :data="orderList" style="width: 100%">
            <el-table-column fixed width="75" prop="processId" label="订单编号">
            </el-table-column>
            <el-table-column label="项目信息" width="280">
              <template slot-scope="scope">
                <div class="row">
                  <span>招标项目编号：</span>
                  <p>{{ scope.row.projectId }}</p>
                </div>
                <div class="row">
                  <span>项目名称：</span>
                  <p>{{ scope.row.projectName2 }}</p>
                </div>
                <div class="row">
                  <span>项目区域：</span>
                  <p>{{ scope.row.mergername }}</p>
                </div>
                <div class="row" v-if="scope.row.tenderTime">
                  <span>开标日期：</span>
                  <p>{{ scope.row.tenderTime }}</p>
                </div>
                <div class="row" v-if="scope.row.bailAmt">
                  <span>{{ scope.row.bizId === '04'? '中标金额：' : '保证金金额：'}}</span>
                  <p>{{ scope.row.bailAmt }}</p>
                </div>
                <div class="row" v-if="scope.row.tendereeName">
                  <span>保函开具对象：</span>
                  <p>{{ scope.row.tendereeName }}</p>
                </div>
                <p></p>
              </template>
            </el-table-column>
            <el-table-column label="业务信息" width="210">
              <template slot-scope="scope">
                <div class="row">
                  <span>业务类型：</span>
                  <p>{{ getBusinessType(scope.row.bizId) }}</p>
                </div>
                <div class="row">
                  <span>申请银行/保险公司：</span>
                  <p>{{ scope.row.dictName }}</p>
                </div>
                <div class="row" v-if="scope.row.premium">
                  <span>保费：</span>
                  <p>{{ scope.row.premium }}</p>
                </div>
                <div class="row" v-if="scope.row.loanAmt">
                  <span>申请保额：</span>
                  <p>{{ scope.row.loanAmt }}</p>
                </div>
                <div class="row" v-if="scope.row.auditEndtime">
                  <span>审批时间：</span>
                  <p>{{ scope.row.auditEndtime }}</p>
                </div>
                <div class="row" v-if="scope.row.reason">
                  <span>未通过原因：</span>
                  <p>{{ scope.row.reason }}</p>
                </div>
                <div v-if="scope.row.status === '08' || scope.row.status === '05'">
                  <div class="row" v-if="scope.row.rejectTime">
                  <span>退款驳回时间：</span>
                  <p>{{ scope.row.rejectTime }}</p>
                </div>
                <div class="row" v-if="scope.row.rejectReason">
                  <span>退款驳回原因：</span>
                  <p>{{ scope.row.rejectReason }}</p>
                </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <p :class="getStatus(scope.row.status).className">
                  {{ getStatus(scope.row.status).text }}
                </p>
              </template>
            </el-table-column>
            <el-table-column width="70" prop="userName" label="申请人">
            </el-table-column>
            <el-table-column label="申请时间" width="93">
              <template slot-scope="scope">
                <p v-if="scope.row.status === '20' || scope.row.status === '21'">{{scope.row.updateStatusTime}}</p>
                <p v-else>{{scope.row.applyTime}}</p>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <!-- 待提交 -->
                <div class="btns" v-if="scope.row.status === '00'">
                  <a href="javascript:void(0);" class="di_in" v-if="scope.row.bizId === '01' || scope.row.bizId === '02'" @click="goNextOrder(scope.row.processId, scope.row.bizId)">继续下单</a>
                  <a href="javascript:void(0);" class="di_in" v-if="scope.row.bizId === '03'" @click="goNextOrder03(scope.row.processId)">继续下单</a>
                  <a href="javascript:void(0);" class="di_in close" @click="closeOrder(scope.row.processId,scope.row.bizId)">关闭订单</a>
                </div>
                <!-- 待支付 -->
                <div class="btns" v-if="scope.row.status === '04'">
                  <a href="javascript:void(0);" class="di_in" @click="showPay(scope.row.processId)">查看支付信息</a>
                  <a href="javascript:;" class="di_in" v-if="scope.row.bankId === 'ZJCZSYYH'" @click="payment1(scope.row.processId)">支付确认</a>
                  <a href="javascript:;" class="di_in" v-if="scope.row.bankId === 'ZJCZSYYH'" @click="payment2(scope.row.processId, scope.$index)">订单取消</a>
                  <el-upload v-if="scope.row.bankId === 'PICC' || scope.row.bankId === 'ZHCX' || scope.row.bankId === 'HBGSRZDB' || scope.row.bankId === 'HBFQDB' " class="upload-demo" :data="{processId:scope.row.processId}" action="/api/www/personalCenter/upload.do" :show-file-list="false" multiple :on-success="fileSuccess" :on-error="fileError" :limit="1">
                    <a href="javascript:void(0);" class="di_in">上传支付凭证</a>
                  </el-upload>
                </div>
                <!-- 审核中 -->
                <!-- scope.row.status === '03' ||
                    scope.row.status === '06' || -->
                <div class="btns" v-if="
                    scope.row.status === '01' ||
                    scope.row.status === '02' ||
                    scope.row.status === '11'
                  ">
                  <a href="javascript:;" class="di_in" v-if="scope.row.bizId === '02'" @click="showOrderIng(scope.row.processId, scope.row.bankId)">查看审核进度</a>
                </div>
                <!-- 审核未通过 -->
                <div class="btns" v-if="scope.row.bizId === '03'">
                  <a href="javascript:;" class="di_in" @click="reApplay(scope.row.processId)">更换机构申请</a>
                </div>
                <!-- 已生成保函 -->
                <div class="btns" v-if="scope.row.status === '08' || showDownInsure(scope.row.bankId, scope.row.policyPath)">
                  <a href="javascript:;" class="di_in" @click="downInsure(scope.row.processId, scope.row.bankId, scope.row.policyPath)">下载保函</a>
                  <a href="javascript:;" class="di_in" v-if="!scope.row.evaluate" @click="showEval(scope.row)">评价</a>
                  <el-rate v-else :value="parseInt(scope.row.evaluate)" :texts="['极差','差','一般','好','极好']" disabled show-text></el-rate>
                </div>
                <div class="btns" v-if="showApplyInvoice(scope.row)">
                  <a href="javascript:;" class="di_in" v-if="!scope.row.fpxzUrl" @click="applyInvoice(scope.row.processId,scope.row.premium)">申请发票</a>
                  <a href="javascript:void(0);" class="di_in close"  v-else @click="showInvoice(scope.row.processId)">下载发票</a>
                </div>
                <div class="btns" v-if="(scope.row.status === '08' || scope.row.status === '05') && !isOpenBid(scope.row.tenderTime)">
                  <a href="javascript:;" class="di_in close" @click="backMoney(scope.row)">申请退款</a>
                </div>
                <div class="btns" v-if="showInvoiceR(scope.row.bankId, scope.row.policyPath,scope.row.status)">
                  <a href="javascript:void(0);" class="di_in close" v-if="!scope.row.fpxzUrl" @click="showInvoice(scope.row.processId)">开具发票</a>
                  <a href="javascript:void(0);" class="di_in close" v-else @click="showInvoice(scope.row.processId)">下载发票</a>
                </div>
                <div class="btns" v-if="isOpenBid(scope.row.tenderTime)">
                  <a class="di_in close" href="javascript:void(0);">保函解保</a>
                </div>
                <!-- 已关闭 -->
                <div class="btns" v-if="(scope.row.status === '12' && !isOpenBid(scope.row.tenderTime)) || (scope.row.status === '13' && !isOpenBid(scope.row.tenderTime))">
                  <a href="javascript:void(0);" class="di_in close" @click="goApply(scope.row.processId)">重新下单</a>
                </div>
                <!-- 点击查看详情目前是订单轮转暂时先不做了 -->
                <!-- <div class="btns">
                  <a href="javascript:void(0);" class="di_in" @click="showDetail(scope.row.processId)">查看详情</a>
                </div> -->

                <div class="btns" v-if="scope.row.status === '20' && getCancalBackTime(scope.row)">
                  <a href="javascript:void(0);" class="di_in close" @click="closeReback(scope.row.processId)">取消退款</a>
                </div>

                <div class="btns" v-if="scope.row.status === '21' && !isOpenBid(scope.row.tenderTime)">
                  <a href="javascript:void(0);" class="di_in close" @click="goApply(scope.row.processId)">重新下单</a>
                  <p :style="{textAlign:'left'}">预计7-14天到账，具体以实际沟通为准</p>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 分页开始 -->
      <div class="paging">
        <el-pagination background layout="prev, pager, next, sizes,jumper,slot" :page-sizes="[10, 20, 30, 50, 100]" :page-size="count" :total="total" :current-page="pageNum" prev-text="上一页" next-text="下一页" @size-change="upCountPage" @current-change="upPage">
          <el-button size="small" class="pagebtn" style="border: 1px solid #dcdcdc;
              box-shadow: 0px 2px 4px 0px rgb(0 117 230 / 77%);
              border-radius: 4px;
              background: #fff;
            ">确定</el-button>
        </el-pagination>
      </div>

      <!-- 分页结束 -->
    </div>
    <!-- 主体结束 -->
    <!-- 开具发票 -->
    <div class="theme-fapiao" v-show="addInvoiceFlag">
      <div class="title p_r">
        <a href="javascript:;" title="关闭" class="close" @click="addInvoiceFlag = false"></a>
        <span class="di_bl flex flex-align-center flex-pack-center">开具发票</span>
      </div>
      <div class="box">
        <div class="item flex flex-align-center" v-show="false">
          <span>发票类型</span><label><input type="radio" name="RadioGroup1" value="单选" id="RadioGroup1_0" checked="checked" />普通发票</label>
        </div>
        <div class="item flex p_r">
          <span>发票抬头</span>
          <input type="text" id="" v-model="invoiceObj.head" class="flex-1 bor"  @blur="checkBlur('head')" @focus="focusDelError('head')" />
          <i>{{ invoiceObjError.head }}</i>
        </div>
        <div class="item flex">
          <span>发票纳税人编号</span>
          <input type="text" id="" v-model="invoiceObj.code" class="flex-1 bor"  @blur="checkBlur('code')" @focus="focusDelError('code')" />
          <i>{{ invoiceObjError.code }}</i>
        </div>
        <div class="item flex">
          <span>发票纳税人地址</span>
          <input type="text" id="" v-model="invoiceObj.address" class="flex-1 bor"  @blur="checkBlur('address')" @focus="focusDelError('address')" />
          <i>{{ invoiceObjError.address }}</i>
        </div>
        <div class="item flex">
          <span>发票纳税人银行</span>
          <input type="text" id="" v-model="invoiceObj.bank" class="flex-1 bor"  @blur="checkBlur('bank')" @focus="focusDelError('bank')" />
          <i>{{ invoiceObjError.bank }}</i>
        </div>
        <div class="item flex">
          <span>发票纳税人账户</span>
          <input type="text" id="" v-model="invoiceObj.bankCode" class="flex-1 bor"  @blur="checkBlur('bankCode')" @focus="focusDelError('bankCode')" />
          <i>{{ invoiceObjError.bankCode }}</i>
        </div>
        <div class="item flex">
          <span>电子发票接收邮箱</span>
          <input type="text" id="" v-model="invoiceObj.email" class="flex-1 bor" @blur="checkBlur('email')" @focus="focusDelError('email')" />
          <i>{{ invoiceObjError.email }}</i>
        </div>
        <div class="item flex">
          <span>电子发票接收联系电话</span>
          <input type="text" id="" v-model="invoiceObj.phone" class="flex-1 bor" @blur="checkBlur('phone')" @focus="focusDelError('phone')" />
          <i>{{ invoiceObjError.phone }}</i>
        </div>
        <div class="item flex">
          <span>备注</span>
          <input type="text" id="" v-model="invoiceObj.remark" class="flex-1 bor" />
        </div>

        <div class="submit flex flex-align-center flex-pack-center">
          <input type="reset" value="取消" @click="addInvoiceFlag = false" />
          <input type="submit" id="" name="" value="开具发票" @click="putInvoice" />
        </div>
      </div>

      <div class="box" v-show="waitFlag">
        <div class="txt te_c">发票加速开具中，请耐心等待~</div>
        <div class="submit flex flex-align-center flex-pack-center">
          <input type="submit" id="" name="" value="确定" @click="waitFlag = false" />
        </div>
      </div>
    </div>

    <div class="theme-popover-mask" v-show="addInvoiceFlag"></div>

    <el-dialog custom-class="shenhe" title="审核进度" :visible.sync="shenhetkVmFlag" width="800" :before-close="handleClose">
      <div class="qipao"><img :src="qipaoSrc" width="150" alt=""></div>
      <el-steps :active="stepActive" finish-status="success" :align-center="true" process-status="finish">
        <el-step v-for="(item,index) in schedules" :key="index" :title="getStepTitle(index,item)"></el-step>
      </el-steps>
      <div class="stepText">{{schedules[stepActive]}}中</div><br>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="closeOrderFlag" width="30%">
      <div class="closeOrderBox">
        <h5>确认关闭此订单吗？</h5>
        <div class="closeOrderBox-item">
          <div class="left">关闭原因：</div>
          <div class="right">
            <el-select v-model="closeOrderRes" placeholder="请选择" @change="closeOrderResError = ''">
              <el-option v-for="item in closeOrderResArr" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <b>{{closeOrderResError}}</b>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeOrderFlag = false">取 消</el-button>
        <el-button type="primary" @click="closeOrderSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="backMoneyFlag" width="30%" :show-close="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="退款原因" prop="resource">
          <el-radio-group v-model="ruleForm.resource" size="mini">
            <el-radio :label="1" border>信息错误，需退款后再次下单</el-radio><br>
            <el-radio :label="2" border>暂不参与此项目投标</el-radio><br>
            <el-radio :label="3" border>其他</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="" prop="textarea" v-show="isOther">
          <el-input type="textarea" :rows="2" placeholder="请输入具体原因" v-model="ruleForm.textarea">
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="mini" @click="submitForm('ruleForm')">确定</el-button>
          <el-button size="mini" @click="closeBackMoney">取消</el-button>
        </el-form-item>

      </el-form>
    </el-dialog>
    <EvaluateVue :dialogVisible="EvaluateVueFlag" @close="closeStar" />
    <DetailDialog :flag="detailDialogFlag" @close="closeDetail" :processid="clickId"></DetailDialog>
    <ApplyInvoice :flag="applyInvoiceFlag" :money="applyInvoiceMoney" :id="applyInvoiceId" @close="closeApplyInvoice"/>
  </div>
</template>

<script>
import orderIcon1 from "../assets/images/icon.png";
import orderIcon2 from "../assets/images/icon2.png";
import orderIcon3 from "../assets/images/icon3.png";
import orderIcon4 from "../assets/images/icon4.png";
import orderIcon5 from "../assets/images/icon5.png";
import orderIcon6 from "../assets/images/icon6.png";
import orderIcon10 from "../assets/images/icon10.png";
import orderIcon11 from "../assets/images/icon11.png";
import orderIcon12 from "../assets/images/icon12.png";
import orderIcon13 from "../assets/images/icon13.png";
import orderIcon14 from "../assets/images/icon14.png";
import orderIcon1Active from "../assets/images/icon-on.png";
import orderIcon2Active from "../assets/images/icon2-on.png";
import orderIcon3Active from "../assets/images/icon3-on.png";
import orderIcon4Active from "../assets/images/icon4-on.png";
import orderIcon5Active from "../assets/images/icon5-on.png";
import orderIcon6Active from "../assets/images/icon6-on.png";
import orderIcon10Active from "../assets/images/icon10-on.png";
import orderIcon11Active from "../assets/images/icon11-on.png";
import orderIcon12Active from "../assets/images/icon12-on.png";
import orderIcon13Active from "../assets/images/icon13-on.png";
import orderIcon14Active from "../assets/images/icon14-on.png";
import cityData from "../assets/js/city";
import Menu from "../components/Menu.vue";
import formCheck from "../utils/js/formCheck";
import EvaluateVue from "../components/Evaluate.vue";
import DetailDialog from "../components/OrderDetail.vue";
import ApplyInvoice from '../components/ApplyInvoice.vue'
export default {
  name: "myorder",
  components: {
    Menu,
    EvaluateVue,
    DetailDialog,
    ApplyInvoice
  },
  data() {
    const checkTextarea = (rule, value, callback) => {
      console.log(rule);
      if (this.ruleForm.resource === 3 && value === "") {
        callback(new Error("请输入退款具体原因"));
      } else {
        callback();
      }
    };
    return {
      applyInvoiceMoney: '',
      applyInvoiceId: '',
      applyInvoiceFlag: false,
      detailDialogFlag: false,
      clickId: "",
      EvaluateVueFlag: false,
      evaluateId: "",
      stepActive: 0,
      myOrderTab: ["保函订单"],
      myOrderTabIndex: 0,
      backMoneyFlag: false,
      backMoneyId: "",
      backStatus: "",
      ruleForm: {
        resource: "",
        textarea: "",
      },
      rules: {
        resource: [
          { required: true, message: "请选择退款原因", trigger: "change" },
        ],
        textarea: [{ validator: checkTextarea, trigger: "blur" }],
      },
      protectOrderTab: [
        {
          iconUrl: orderIcon1,
          iconUrlActive: orderIcon1Active,
          name: "全部状态",
          value: "",
        },
        {
          iconUrl: orderIcon2,
          iconUrlActive: orderIcon2Active,
          name: "待提交", //继续下单|关闭订单
          value: "00",
        },
        {
          iconUrl: orderIcon3,
          iconUrlActive: orderIcon3Active,
          name: "审核中",
          value: "01",
        },
        {
          iconUrl: orderIcon4,
          iconUrlActive: orderIcon4Active,
          name: "审核未通过",
          value: "03",
        },
        {
          iconUrl: orderIcon5,
          iconUrlActive: orderIcon5Active,
          name: "待支付", // 查看支付信息|上传支付凭证
          value: "04",
        },
        {
          iconUrl: orderIcon10,
          iconUrlActive: orderIcon10Active,
          name: "已支付",
          value: "05",
        },
        {
          iconUrl: orderIcon6,
          iconUrlActive: orderIcon6Active,
          name: "已生成保函", // 下载保函|开具发票|下载发票
          value: "08",
        },
        {
          iconUrl: orderIcon13,
          iconUrlActive: orderIcon13Active,
          name: "退保中",
          value: "20",
        },
        {
          iconUrl: orderIcon14,
          iconUrlActive: orderIcon14Active,
          name: "已退保",
          value: "21",
        },
        {
          iconUrl: orderIcon11,
          iconUrlActive: orderIcon11Active,
          name: "已关闭", //重新下单
          value: "09",
        },
      ],
      protectOrderTabIndex: 0,
      protectTypeTab: [
        { value: "", label: "全部保函" },
        { value: "03", label: "投标保函" },
        { value: "04", label: "履约保函" },
        { value: "05", label: "预付款保函" },
        { value: "09", label: "支付保函" },
        { value: "10", label: "施工意外险" },
        { value: "06", label: "质量保函" },
        { value: "07", label: "农民工资险" },
      ],
      protectTypeTabIndex: 0,
      loanOrderTab: [
        {
          iconUrl: orderIcon1,
          iconUrlActive: orderIcon1Active,
          name: "全部状态",
          value: "",
        },
        {
          iconUrl: orderIcon2,
          iconUrlActive: orderIcon2Active,
          name: "待提交",
          value: "00",
        },
        {
          iconUrl: orderIcon3,
          iconUrlActive: orderIcon3Active,
          name: "审核中",
          value: "01",
        },
        {
          iconUrl: orderIcon4,
          iconUrlActive: orderIcon4Active,
          name: "审核未通过",
          value: "03",
        },
        {
          iconUrl: orderIcon5,
          iconUrlActive: orderIcon5Active,
          name: "待放款",
          value: "11",
        },
        {
          iconUrl: orderIcon12,
          iconUrlActive: orderIcon12Active,
          name: "已放款",
          value: "06",
        },
        {
          iconUrl: orderIcon11,
          iconUrlActive: orderIcon11Active,
          name: "已关闭",
          value: "09",
        },
      ],
      loanOrderTabIndex: 0,
      cityData: [{ value: "", label: "所有城市" }],
      orderList: [],
      cityValue: "",
      dateValue: "",
      idValue: "",
      count: 10,
      total: 0,
      pageNum: 1,
      orderType: "",
      addInvoiceFlag: false,
      waitFlag: false,
      invoiceObj: {
        head: "",
        code: "",
        address: "",
        bank: "",
        bankCode: "",
        email: "",
        phone: "",
        remark: "",
      },
      invoiceObjError: {
        head: "",
        code: "",
        address: "",
        bank: "",
        bankCode: "",
        email: "",
        phone: "",
        remark: "",
      },
      imgMaps: [],
      schedules: [
        "约访企业",
        "审核材料",
        "联系担保",
        "担保审核",
        "上报分行",
        "上报省行",
        "放款",
        "放款",
      ],
      shenhetkVmFlag: false,
      imgMapKey: ["labelId", "imgName"],
      qipaoSrc: "",
      closeOrderFlag: false,
      closeOrderRes: "",
      closeOrderResError: "",
      closeOrderProcessId: "",
      closeOrderResArr: [
        { label: "信息错误/变更，重新下单", value: "12" },
        { label: "有变动不下单", value: "13" },
      ],
    };
  },
  created() {
    this.cityData = cityData;
  },
  computed: {
    isOther() {
      return this.ruleForm.resource === 3;
    },
  },
  mounted() {
    let status = "";
    if (
      this.$route.query &&
      this.$route.query.type &&
      this.$route.query.type !== ""
    ) {
      status = this.$route.query.type;
      this.protectOrderTab.forEach((v, k) => {
        if (v.value === status) {
          this.protectOrderTabIndex = k;
        }
      });
    }
    if (
      this.$route.query &&
      this.$route.query.tab &&
      this.$route.query.tab !== ""
    ) {
      this.myOrderTabIndex = parseInt(this.$route.query.tab);
    }
    this.getOrderList();
  },
  methods: {
    closeApplyInvoice(flag) {
      this.applyInvoiceFlag = false
      this.applyInvoiceId = ''
      this.applyInvoiceMoney = ''
      if (flag) {
        this.getOrderList()
      }
    },
    applyInvoice(id,money) {
      this.applyInvoiceId = id
      this.applyInvoiceMoney = money
      this.applyInvoiceFlag = true
    },
    // 显示申请发票的规则
    showApplyInvoice(res) {
      const bankArr = ['DUBON','XTDB', 'XTRDB', 'NBXTRZDB', 'NRDDB', 'ZJLFGCDB', 'TZZBRZDB', 'SCJXDB','SCHYCFDB']
      if (res.status === '08' && bankArr.includes(res.bankId)) {
        return true
      }
      return false
    },
    // 取消申请退款
    closeBackMoney() {
      this.$refs['ruleForm'].resetFields();
      this.backMoneyFlag = false
    },
    // 取消退款显示的逻辑
    getCancalBackTime(v) {
      const dateString = v.updateStatusTime.replace(/-/g,'/')
      const applyTime = new Date(dateString).getTime();
      const nowTime = new Date().getTime();
      return nowTime - applyTime < 1000 * 60 * 5;
    },
    // 取消退款的事件
    async closeReback(id) {
      const res = await this.$getAPI.reBackCancal({
        processId: id,
        message: "",
        status: "20",
      });
      const _this = this;
      console.log(res)
      if (res.code === "01" || res.code === "02") {
        const text = res.status === "08" ? "已生成保函" : res.status === "05" ? "已支付" :"已退保";
        this.$alert((res.code === "02" ? res.message : `此笔订单，已恢复至“${text}“列表中`), "",{
          confirmButtonText: "我知道了",
          callback: () => {
            console.log('取消退款')
            _this.getOrderList();
          },
        });
      } 
    },
    showDetail() {
      this.detailDialogFlag = true;
    },
    closeDetail() {
      this.detailDialogFlag = false;
    },
    backMoney(v) {
      this.backMoneyId = v.processId;
      this.backStatus = v.status;
      this.backMoneyFlag = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.rebackApply();
        } else {
          return false;
        }
      });
    },
    async rebackApply() {
      const res = await this.$getAPI.reBackApply({
        processId: this.backMoneyId,
        status: this.backStatus,
        message:
          this.ruleForm.resource === 1
            ? "信息错误，需退款后再次下单"
            : this.ruleForm.resource === 2
            ? "暂不参与此项目投标"
            : this.ruleForm.textarea,
      });
      if (res.code === "01") {
        this.protectOrderTabIndex = 7;
        this.getOrderList();
        this.backMoneyId = "";
        this.backStatus = "";
        this.ruleForm.resource = "";
        this.closeBackMoney()
      }
    },
    showEval(obj) {
      this.EvaluateVueFlag = true;
      this.evaluateId = obj.processId;
    },
    // 评价完成
    closeStar(index) {
      this.EvaluateVueFlag = false;
      if (index > 0) {
        // 将未评价状态改为已评价并且展示星级
        this.apiEvaluate(index);
      }
    },
    // 评价记录的接口
    async apiEvaluate(index) {
      const res = await this.$getAPI.putEvaluate({
        order_id: this.evaluateId,
        value: index,
      });
      if (res.code === 200) {
        if (res.result.resultCode === 1) {
          const i = this.orderList.findIndex(
            (v) => v.processId === this.evaluateId
          );
          this.orderList[i].evaluate = index;
        }
      }
      console.log(res);
    },
    isOpenBid(openT) {
      let nt = new Date().getTime();
      let ot = new Date(openT).getTime();
      return nt >= ot;
    },
    async reApi(id) {
      let res = await this.$getAPI.reapply({
        processId: id,
      });
      if (res.resultCode === "1") {
        this.$alert("请重新提交申请，并选择新的金融机构", "提示", {
          confirmButtonText: "确定",
          callback: () => {
            this.getOrderList();
          },
        });
      } else {
        this.$message.error("操作失败，请重新操作，如果多次失败，请联系客服");
      }
    },
    async reApplay(id) {
      this.$confirm("您确定更换金融机构重新申请吗？", "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
        .then(() => {
          this.reApi(id);
        })
        .catch(() => {});
    },
    add0(num) {
      return num >= 10 ? num : `0${num}`;
    },
    ymdsmmToYMD(str) {
      return str.substr(0, 10);
    },
    searchGetOrderList() {
      this.pageNum = 1;
      this.getOrderList();
    },
    async getOrderList() {
      let res = await this.$getAPI.getMyOrder({
        pageNum: this.pageNum,
        pageSize: this.count,
        bizId:
          this.myOrderTabIndex === 0
            ? this.protectTypeTab[this.protectTypeTabIndex].value
            : "02",
        areaCode: this.cityValue[this.cityValue.length - 1],
        projectId: this.idValue,
        status:
          this.myOrderTabIndex === 0
            ? this.protectOrderTab[this.protectOrderTabIndex].value
            : this.loanOrderTab[this.loanOrderTabIndex].value,
        startTime: !this.dateValue
          ? ""
          : `${new Date(this.dateValue[0]).getFullYear()}-${this.add0(
              new Date(this.dateValue[0]).getMonth() + 1
            )}-${this.add0(new Date(this.dateValue[0]).getDate())} 00:00:00`,
        endTime: !this.dateValue
          ? ""
          : `${new Date(this.dateValue[1]).getFullYear()}-${this.add0(
              new Date(this.dateValue[1]).getMonth() + 1
            )}-${this.add0(new Date(this.dateValue[1]).getDate())} 23:59:59`,
      });
      this.orderList = res.data;
      this.total = res.total;
    },
    indexMethod(index) {
      return this.add0(String(index + 1 + (this.pageNum - 1) * this.count));
    },
    getBusinessType(code) {
      let text = "";
      switch (code) {
        case "01":
          text = "投标贷";
          break;
        case "02":
          text = "中标贷";
          break;
        case "03":
          text = "投标保函";
          break;
        case "04":
          text = "履约保函";
          break;
        case "05":
          text = "预付款保函";
          break;
        case "06":
          text = "质量保函";
          break;
        case "07":
          text = "农民工资险";
          break;
        case "09":
          text = "支付保函";
          break;
        case "10":
          text = "施工意外险";
          break;
      }
      return text;
    },
    upCountPage(count) {
      this.count = count;
      this.pageNum = 1;
      this.getOrderList();
    },
    upPage(pageNum) {
      this.pageNum = pageNum;
      this.getOrderList();
    },
    upProtectOrderTab(k) {
      this.pageNum = 1;
      this.protectOrderTabIndex = k;
      this.getOrderList();
    },
    upProtectTypeTab(k) {
      this.pageNum = 1;
      this.protectTypeTabIndex = k;
      this.getOrderList();
    },
    upLoanOrderTab(k) {
      this.pageNum = 1;
      this.loanOrderTabIndex = k;
      this.getOrderList();
    },
    clear() {
      this.idValue = "";
      this.cityValue = "";
      this.dateValue = "";
      this.pageNum = 1;
      this.getOrderList();
    },
    upMyOrderTab(k) {
      this.pageNum = 1;
      this.myOrderTabIndex = k;
      this.getOrderList();
    },
    getStatus(status) {
      let text = "";
      let className = "";
      switch (status) {
        case "00":
          text = "待提交";
          className = "green";
          break;
        case "01":
          text = "审核中";
          className = "green";
          break;
        case "02":
          text = "审核通过";
          className = "blue";
          break;
        case "03":
          text = "审核未通过";
          className = "red";
          break;
        case "04":
          text = "待支付";
          className = "green";
          break;
        case "05":
          text = "已支付";
          className = "blue";
          break;
        case "06":
          text = "已放款";
          className = "blue";
          break;
        case "07":
          text = "已失效";
          className = "";
          break;
        case "08":
          text = "已生成保函";
          className = "blue";
          break;
        case "09":
          text = "已作废";
          className = "";
          break;
        case "10":
          text = "已完成";
          className = "blue";
          break;
        case "11":
          text = "待放款";
          className = "green";
          break;
        case "12":
          text = "信息错误/变更，重新下单";
          className = "";
          break;
        case "13":
          text = "有变动不下单";
          className = "";
          break;
        case "14":
          text = "待支付-已开标";
          className = "";
          break;
        case "20":
          text = "审核中";
          className = "";
          break;
        case "21":
          text = "已退款";
          className = "";
          break;
      }
      return { text, className };
    },
    async showPay(processId) {
      let res = await this.$getAPI.toPay({ processId });
      let host = process.env.VUE_APP_BASE_URL;
      let data = res.data;
      if (data.success == "0") {
        location.href = `${host}/guaranteeCtrl/toPICCPayHtml.do?processId=${processId}`;
      } else if (data.success == "1") {
        location.href = `${host}/guaranteeCtrl/toPAICPayHtml.do?processId=${processId}`;
      } else if (data.success == "2") {
        window.location.href = data.payUrl;
      } else if (data.success == "3") {
        location.href = `${host}/guaranteeCtrl/toPOFGCOMPayHtml.do?processId=${processId}`;
      } else if (data.success == "4") {
        location.href = `${host}/guaranteeCtrl/toSCXZCXRZDBPayHtml.do?processId=${processId}`;
      } else if (data.success == "5") {
        location.href = `${host}/guaranteeCtrl/toSZDBPayHtml.do?processId=${processId}`;
      } else if (data.success == "6") {
        location.href = `${host}/guaranteeCtrl/toZHCXPayHtml.do?processId=${processId}`;
      } else if (data.success == "7") {
        location.href = `${host}/guaranteeCtrl/toTPICPayHtml.do?processId=${processId}`;
      } else if (data.success == "8") {
        location.href = `${host}/guaranteeCtrl/toZJXYRZDBKMPayHtml.do?processId=${processId}`;
      } else if (data.success == "9") {
        location.href = `${host}/guaranteeCtrl/toZJCZSYYHPayHtml.do?processId=${processId}`;
      } else if (data.success == "10") {
        location.href = `${host}/guaranteeCtrl/toICCPayHtml.do?processId=${processId}`;
      } else if (data.success == "11") {
        location.href = `${host}/guaranteeCtrl/toNBZDRZDBPayHtml.do?processId=${processId}`;
      } else if (data.success == "12") {
        location.href = `${host}/guaranteeCtrl/toNBYYRZDBPayHtml.do?processId=${processId}`;
      } else if (data.success == "13") {
        location.href = `${host}/guaranteeCtrl/toYYZXQYRZDBPayHtml.do?processId=${processId}`;
      } else if (data.success == "14") {
        location.href = `${host}/guaranteeCtrl/toLSZCXDBPayHtml.do?processId=${processId}`;
      } else if (data.success == "15") {
        location.href = `${host}/guaranteeCtrl/toCPICPayHtml.do?processId=${processId}`;
      } else if (data.success == "16") {
        location.href = `${host}/guaranteeCtrl/toICBCPayHtml.do?processId=${processId}`;
      } else if (data.success == "17") {
        location.href = `${host}/guaranteeCtrl/toHBGSRZDBPayHtml.do?processId=${processId}`;
      } else if (data.success == "18") {
        location.href = `${host}/guaranteeCtrl/toHZBANKPayHtml.do?processId=${processId}`;
      } else if (data.success == "19") {
        location.href = `${host}/guaranteeCtrl/toYNHRSRZDBPayHtml.do?processId=${processId}`;
      } else if (data.success == "20") {
        location.href = `${host}/guaranteeCtrl/toHBFQDBPayHtml.do?processId=${processId}`;
      } else if (data.success == "21") {
        location.href = `${host}/guaranteeCtrl/toZCDBPayHtml.do?processId=${processId}`;
      } else if (data.success == "22") {
        location.href = `https://api.zhonghuijingji.com/ecooperation/pingan/Route/Ecooperation?toubaono=${data.pab.toubaono}&platformcode=${data.pab.platformcode}&timestamp=${data.pab.timestamp}&sign=${data.pab.sign}`;
        // location.href = `http://118.31.16.107:6392/Route/Ecooperation?toubaono=${data.pab.toubaono}&platformcode=${data.pab.platformcode}&timestamp=${data.pab.timestamp}&sign=${data.pab.sign}`
      } else if (data.success == "23") {
        location.href = `${host}/guaranteeCtrl/toZHCXLSPayHtml.do?processId=${processId}`;
      } else if (data.success == "24") {
        location.href = data.url;
      } else if (data.success == "25") {
        location.href = `${host}/guaranteeCtrl/toZHDBPayHtml.do?processId=${processId}`;
      } else if (data.success == "26") {
        location.href = `${host}/guaranteeCtrl/toLQRSPayHtml.do?processId=${processId}`;
      } else if (data.success == "27") {
        location.href = `http://47.114.111.138:8003/Route/Ecooperation?toubaono=${data.pab.toubaono}&platformcode=${data.pab.platformcode}&timestamp=${data.pab.timestamp}&sign=${data.pab.sign}`;
        // location.href = `http://118.31.16.107:6098/Route/Ecooperation?toubaono=${data.pab.toubaono}&platformcode=${data.pab.platformcode}&timestamp=${data.pab.timestamp}&sign=${data.pab.sign}`
      } else if (data.success == "28") {
        location.href = data.url;
      } else if (data.success == "29") {
        location.href = data.url;
      } else if (data.success == "30") {
        location.href = data.url;
      } else if (data.success == "31") {
        // location.href = `http://118.31.16.107:6092/Route/Ecooperation?toubaono=${data.pab.toubaono}&platformcode=${data.pab.platformcode}&timestamp=${data.pab.timestamp}&sign=${data.pab.sign}`
        location.href = `https://api.zhonghuijingji.com/ecooperation/Route/Ecooperation?toubaono=${data.pab.toubaono}&platformcode=${data.pab.platformcode}&timestamp=${data.pab.timestamp}&sign=${data.pab.sign}`;
      } else if (data.success == "32") {
        // location.href = `http://118.31.16.107:6092/yongan/Route/Ecooperation?toubaono=${data.pab.toubaono}&platformcode=${data.pab.platformcode}&timestamp=${data.pab.timestamp}&sign=${data.pab.sign}`
        location.href = `https://api.zhonghuijingji.com/ecooperation/yongan/Route/Ecooperation?toubaono=${data.pab.toubaono}&platformcode=${data.pab.platformcode}&timestamp=${data.pab.timestamp}&sign=${data.pab.sign}`;
      } else if (data.success == "33") {
        // location.href = `http://http://118.31.16.107:6092/tianan/Route/Ecooperation?toubaono=${data.pab.toubaono}&platformcode=${data.pab.platformcode}&timestamp=${data.pab.timestamp}&sign=${data.pab.sign}`
        location.href = `https://api.zhonghuijingji.com/ecooperation/tianan/Route/Ecooperation?toubaono=${data.pab.toubaono}&platformcode=${data.pab.platformcode}&timestamp=${data.pab.timestamp}&sign=${data.pab.sign}`;
      } else if (data.success == "34") {
        location.href = `${host}/guaranteeCtrl/toZBGXPayHtml.do?processId=${processId}`;
      } else if (data.success == "35") {
        location.href = `${host}/guaranteeCtrl/toYHDBPayHtml.do?processId=${processId}`;
      } else if (data.success == "36") {
        location.href = `${host}/guaranteeCtrl/toZTBPayHtml.do?processId=${processId}`;
      } else if (data.success == "37") {
        location.href = `${host}/guaranteeCtrl/toZLTXPayHtml.do?processId=${processId}`;
      } else if (data.success == "38") {
        location.href = `${host}/guaranteeCtrl/toSXDBPayHtml.do?processId=${processId}`;
      } else if (data.success == "39") {
        location.href = `${host}/guaranteeCtrl/toXDCXPayHtml.do?processId=${processId}`;
      } else if (data.success == "40") {
        location.href = `${host}/guaranteeCtrl/toMXHBPayHtml.do?processId=${processId}`;
      } else if (data.success == "41") {
        location.href = `${host}/guaranteeCtrl/toQTZCDBPayHtml.do?processId=${processId}`;
      } else if (data.success == "666") {
        location.href = `${host}/guaranteeCtrl/toGuaranteeApproveResult.do?processId=${processId}`;
      } else {
        this.$message.error("调用支付接口失败!!!" + data.msg);
        return false;
      }
    },
    closeOrder(processId, bizId) {
      this.closeOrderProcessId = processId;
      if (bizId === "03") {
        this.closeOrderFlag = true;
      } else {
        this.$getAPI
          .closeOrder({ processId: this.closeOrderProcessId })
          .then((res) => {
            if (res.success) {
              this.closeOrderRes = "";
              if (this.orderList.length === 1 && this.pageNum > 0) {
                this.pageNum--;
              }
              this.getOrderList();
            }
          });
      }
    },
    closeOrderSubmit() {
      if (!this.closeOrderRes) {
        this.closeOrderResError = "请选择关闭原因";
        return;
      }
      this.closeOrderFlag = false;
      this.$getAPI
        .closeOrders({
          processId: this.closeOrderProcessId,
          status: this.closeOrderRes,
        })
        .then((res) => {
          if (res.success) {
            this.closeOrderRes = "";
            if (this.orderList.length === 1 && this.pageNum > 0) {
              this.pageNum--;
            }
            this.getOrderList();
          }
        });
    },
    async showInvoice(processId) {
      let res = await this.$getAPI.addInvoice({ processId });
      let data = res.data;
      if (data.success == "1") {
        this.invoiceId = processId;
        this.addInvoiceFlag = true;
      } else if (data.success == "2") {
        this.$alert("发票尚未生成，银行正在处理中，请稍后再试", "警告", {
          showConfirmButton: false,
          callback() {},
        });
      } else if (data.success == "3") {
        window.location.href = data.url;
      } else if (data.success == "4") {
        this.$alert(
          `发票${data.msg}尚未生成，银行正在处理中，请稍后再试`,
          "警告",
          { showConfirmButton: false, callback() {} }
        );
      } else if (data.success == "5") {
        this.$alert("发票申请已发送，请两小时后到个人中心下载发票！", "警告", {
          showConfirmButton: false,
          callback() {},
        });
      } else if (data.success == "6") {
        this.$alert("暂不支持下载电子发票，请联系客服邮寄纸质发票！", "警告", {
          showConfirmButton: false,
          callback() {},
        });
      } else if (data.success == "7") {
        window.open(data.url);
      }
      // else if (data.success == '11') {
      //   // location.href = `http://118.31.16.107:6092/pingan/Route/Ecooperation?toubaono=${data.pab.toubaono}&platformcode=${data.pab.platformcode}&timestamp=${data.pab.timestamp}&sign=${data.pab.sign}`
      //   window.open(`http://118.31.16.107:6092/pingan/Route/Ecooperation?toubaono=${data.pab.toubaono}&platformcode=${data.pab.platformcode}&timestamp=${data.pab.timestamp}&sign=${data.pab.sign}`,'_blank');
      // } else if (data.success == '12') {
      //   location.href = `http://118.31.16.107:6098/Route/Ecooperation?toubaono=${data.pab.toubaono}&platformcode=${data.pab.platformcode}&timestamp=${data.pab.timestamp}&sign=${data.pab.sign}`
      //
      // } else if (data.success == '13') {
      //   location.href = `http://118.31.16.107:6092/Route/Ecooperation?toubaono=${data.pab.toubaono}&platformcode=${data.pab.platformcode}&timestamp=${data.pab.timestamp}&sign=${data.pab.sign}`
      //
      // } else if (data.success == '14') {
      //   location.href = `http://118.31.16.107:6092/yongan/Route/Ecooperation?toubaono=${data.pab.toubaono}&platformcode=${data.pab.platformcode}&timestamp=${data.pab.timestamp}&sign=${data.pab.sign}`
      //
      // } else if (data.success == '15') {
      //   // location.href = `http://http://118.31.16.107:6092/tianan/Route/Ecooperation?toubaono=${data.pab.toubaono}&platformcode=${data.pab.platformcode}&timestamp=${data.pab.timestamp}&sign=${data.pab.sign}`
      //   location.href = `https://api.zhonghuijingji.com/ecooperation/tianan/Route/Ecooperation?toubaono=${data.pab.toubaono}&platformcode=${data.pab.platformcode}&timestamp=${data.pab.timestamp}&sign=${data.pab.sign}`
      //
      // }
    },
    async putInvoice() {
      if (
        !(
          this.checkBlur("head") &&
          this.checkBlur("code") &&
          this.checkBlur("address") &&
          this.checkBlur("bank") &&
          this.checkBlur("bankCode") &&
          this.checkBlur("email") &&
          this.checkBlur("phone")
        )
      ) {
        return false;
      }
      let obj = {
        invoiceOwner: this.invoiceObj.head,
        taxPayerNO: this.invoiceObj.code,
        taxPayerAddress: this.invoiceObj.address,
        taxPayerBankName: this.invoiceObj.bank,
        taxPayerBankAccount: this.invoiceObj.bankCode,
        invoiceEmail: this.invoiceObj.email,
        invoicePhone: this.invoiceObj.phone,
        remark: this.invoiceObj.remark,
        processId: this.invoiceId,
      };
      let data = await this.$getAPI.updateInvoice(obj);
      if (data.success == "1") {
        this.addInvoiceFlag = false;
        window.location.href = data.url;
      } else if (data.success == "2") {
        this.addInvoiceFlag = false;
        this.waitFlag = true;
      }
    },
    checkBlur(from) {
      let formTextObj = {
        head: formCheck.checkInvoiceHead(this.invoiceObj[from]),
        code: formCheck.checkInvoiceCode(this.invoiceObj[from]),
        address: formCheck.checkInvoiceAddress(this.invoiceObj[from]),
        bank: formCheck.checkInvoiceBank(this.invoiceObj[from]),
        bankCode: formCheck.checkInvoiceBankCode(this.invoiceObj[from]),
        email: formCheck.checkInvoiceEmail(this.invoiceObj[from]),
        phone: formCheck.checkInvoicePhone(this.invoiceObj[from]),
      };
      if (typeof formTextObj[from] === "string") {
        this.invoiceObjError[from] = formTextObj[from];
        return false;
      }
      console.log(from);
      return true;
    },
    // 获取焦点报错重置
    focusDelError(from) {
      this.invoiceObjError[from] = "";
    },
    goNextOrder(processId, bizId) {
      let host = process.env.VUE_APP_BASE_URL;
      location.href = `${host}/bidTenderLoansBank/toUpload.do?processId=${processId}&bizid=${bizId}`;
    },
    // 查询审核进度
    async showOrderIng(processId, bankId) {
      let res = await this.$getAPI.seachShenhe({ pid: processId });
      this.changeLatch(res.data.data.STATUS, bankId, res.data.data);
    },
    async payment1(id) {
      let res = await this.$getAPI.payment({ processId: id, payType: 1 });
      let data = res.data;
      if (data.success == "01") {
        //已支付
        this.$alert(`已支付`, "提示", {
          showConfirmButton: false,
          callback() {},
        });
      } else if (data.success == "02") {
        //未支付
        if ("" != data.message) {
          this.$alert(data.message, "提示", {
            showConfirmButton: false,
            callback() {},
          });
        } else {
          this.$alert(`未支付`, "提示", {
            showConfirmButton: false,
            callback() {},
          });
        }
      } else {
        //请求失败
        this.$alert(`请求失败${data.message}`, "提示", {
          showConfirmButton: false,
          callback() {},
        });
      }
    },
    /* 订单取消接口  */
    async payment2(id, key) {
      console.log(id, key);
      let res = await this.$getAPI.payment({ processId: id, payType: 2 });
      let data = res.data;
      let msg = data.msg ? data.msg : "";
      if (data.success == "03") {
        //取消成功
        this.$alert(`取消成功`, "提示", {
          showConfirmButton: false,
          callback() {},
        });
        location.reload();
      } else if (data.success == "04") {
        //取消失败
        if (msg) {
          this.$alert(msg, "提示", { showConfirmButton: false, callback() {} });
        } else {
          this.$alert("取消失败(已支付状态或开函状态，无法取消)", "提示", {
            showConfirmButton: false,
            callback() {},
          });
        }
      } else {
        //请求失败
        this.$alert(`请求失败${msg}`, "提示", {
          showConfirmButton: false,
          callback() {},
        });
      }
    },
    goNextOrder03(processId) {
      let host = process.env.VUE_APP_BASE_URL;
      location.href = `${host}/personalCenter/continueApply.do?processId=${processId}`;
    },
    showInvoiceR(bankId, POLICYPATH, status) {
      if (bankId === "PAIC" && POLICYPATH === "05") {
        //这里POLICYPATH是status
        return true;
      }
      if (bankId === "NBYYRZDB" && status === "08") {
        return true;
      }
      if (bankId === "PAIC" && status === "08") {
        return true;
      }
      if (bankId === "ICBC" && status === "08") {
        return true;
      }
      if (bankId === "CPIC" && status === "08") {
        return true;
      }
      if (bankId === "PICC" && status === "08") {
        return true;
      }
      if (bankId === "POFGCOM" && status === "08") {
        return true;
      }
      if (bankId === "QTZCDB" && status === "08") {
        return true;
      }
      if (bankId === "NBZDRZDB" && status === "08") {
        return true;
      }
      if (bankId === "SCXZCXRZDB" && status === "08") {
        return true;
      }
      if (bankId === "ZHCX" && status === "08") {
        return true;
      }
      if (bankId === "ZJXYRZDBKM" && status === "08") {
        return true;
      }
      if (bankId === "EGBANK" && status === "08") {
        return true;
      }
      if (bankId === "TPIC" && status === "08") {
        return true;
      }
      if (bankId === "ICC" && status === "08") {
        return true;
      }
      if (bankId === "ZJCZSYYH" && status === "08") {
        return true;
      }
      if (bankId === "YNRLRZDB" && status === "08") {
        return true;
      }
      if (bankId === "HBGSRZDB" && status === "08") {
        return true;
      }
      if (bankId === "HBFQDB" && status === "08") {
        return true;
      }
      if (bankId === "YNHRSRZDB" && status === "08") {
        return true;
      }
      if (bankId === "HZBANKLS" && status === "08") {
        return true;
      }
      if (bankId === "HZBANKNB" && status === "08") {
        return true;
      }
      if (bankId === "ZCDB" && status === "08") {
        return true;
      }
      if (bankId === "ZHCXLS" && status === "08") {
        return true;
      }
      if (bankId === "ZYHX" && status === "08") {
        return true;
      }
      if (bankId === "ZHDB" && status === "08") {
        return true;
      }
      if (bankId === "ZGTB" && status === "08") {
        return true;
      }
      if (bankId === "YHDB" && status === "08") {
        return true;
      }
      if (bankId === "PABDY" && status === "08") {
        return true;
      }
      if (bankId === "ZGRS" && status === "08") {
        return true;
      }
      if (bankId === "ZGRB" && status === "08") {
        return true;
      }
      if (bankId === "YACX" && status === "08") {
        return true;
      }
      if (bankId === "TACX" && status === "08") {
        return true;
      }
      if (bankId === "ZBGX" && status === "08") {
        return true;
      }
      if (bankId === "ZTB" && status === "08") {
        return true;
      }
      if (bankId === "XDCX" && status === "08") {
        return true;
      }
      if (bankId === "SXDB" && status === "08") {
        return true;
      }
      if (bankId === "MXHDB" && status === "08") {
        return true;
      }
      if (bankId === "PAB" && status === "08") {
        return true;
      }
      if (bankId === "DUBON" && status === "08") {
        return true;
      }
      if (bankId === "PABLS" && status === "08") {
        return true;
      }
      if (bankId === "HFGK" && status === "08") {
        return true;
      }
      if (bankId === "JMDB" && status === "08") {
        return true;
      }
      if (bankId === "HSICBC" && status === "08") {
        return true;
      }
      return false;
    },
    showDownInsure(bankId, POLICYPATH) {
      if (bankId === "PAIC" && POLICYPATH === "05") {
        //这里POLICYPATH是status
        return true;
      }
      return false;
    },
    async downInsure(processId, bankId, POLICYPATH) {
      let host = process.env.VUE_APP_BASE_URL;
      if (bankId === "PAIC" && POLICYPATH === "05") {
        //这里POLICYPATH是status
        let res = await this.$getAPI.download({ processId });
        if (res.success == "1") {
          location.href = `${host}/personalCenter/query/baohan.do?filePath=${encodeURI(
            encodeURI(res.filePath)
          )}`;
        } else {
          this.$alert(`保函尚未生成，银行正在处理中，请稍后再试`, "警告", {
            showConfirmButton: false,
            callback() {},
          });
        }
      } else if (bankId === "PAIC" && POLICYPATH === "08") {
        //这里POLICYPATH是status
        let res = await this.$getAPI.download({ processId });
        if (res.success == "1") {
          location.href = `${host}/personalCenter/query/baohan.do?filePath=${encodeURI(
            encodeURI(res.filePath)
          )}`;
        } else {
          this.$alert(`保函尚未生成，银行正在处理中，请稍后再试`, "警告", {
            showConfirmButton: false,
            callback() {},
          });
        }
      } else if (bankId === "CPIC") {
        //这里POLICYPATH是status
        window.open(POLICYPATH);
      } else if (bankId === "ICBC") {
        //这里POLICYPATH是status
        window.open(POLICYPATH);
      } else if (bankId === "HBGSRZDB") {
        //这里POLICYPATH是status
        window.open(POLICYPATH);
      } else if (bankId === "HBFQDB") {
        //这里POLICYPATH是status
        window.open(POLICYPATH);
      } else if (bankId === "PICC") {
        window.location.href = POLICYPATH;
      } else if (bankId === "NBZDRZDB") {
        window.location.href = POLICYPATH;
      } else if (bankId === "NBYYRZDB") {
        window.location.href = POLICYPATH;
      } else if (bankId === "YNHRSRZDB") {
        window.location.href = POLICYPATH;
      } else if (bankId === "YYZXQYRZDB") {
        window.location.href = POLICYPATH;
      } else if (bankId === "LSZCXDB") {
        window.location.href = POLICYPATH;
      } else if (bankId === "PAB") {
        window.location.href = POLICYPATH;
      } else if (bankId === "POFGCOM") {
        window.location.href = POLICYPATH;
      } else if (bankId === "QTZCDB") {
        window.location.href = POLICYPATH;
      } else if (bankId === "SCXZCXRZDB") {
        window.location.href = POLICYPATH;
      } else if (bankId === "CCBSZDBKM") {
        window.location.href = POLICYPATH;
      } else if (bankId === "CCBSZDBJN") {
        window.location.href = POLICYPATH;
      } else if (bankId === "ZJXYRZDB") {
        window.location.href = POLICYPATH;
      } else if (bankId === "ZHCX") {
        window.location.href = POLICYPATH;
      } else if (bankId === "TPIC") {
        window.location.href = POLICYPATH;
      } else if (bankId === "ZJXYRZDBKM") {
        window.location.href = POLICYPATH;
      } else if (bankId === "ZJCZSYYH") {
        window.location.href = POLICYPATH;
      } else if (bankId === "YNRLRZDB") {
        window.location.href = POLICYPATH;
      } else if (bankId === "ICC") {
        window.location.href = POLICYPATH;
      } else if (bankId === "HZBANKLS") {
        window.location.href = POLICYPATH;
      } else if (bankId === "HZBANKNB") {
        window.location.href = POLICYPATH;
      } else if (bankId === "ZCDB") {
        window.location.href = POLICYPATH;
      } else if (bankId === "ZHCXLS") {
        window.location.href = POLICYPATH;
      } else if (bankId === "ZYHX") {
        window.location.href = POLICYPATH;
      } else if (bankId === "PABDY") {
        window.location.href = POLICYPATH;
      } else if (bankId === "ZGRS") {
        window.location.href = POLICYPATH;
      } else if (bankId === "LQRS") {
        window.location.href = POLICYPATH;
      } else if (bankId === "ZGTB") {
        window.location.href = POLICYPATH;
      } else if (bankId === "ZGRB") {
        window.location.href = POLICYPATH;
      } else if (bankId === "YACX") {
        window.location.href = POLICYPATH;
      } else if (bankId === "ZBGX") {
        window.location.href = POLICYPATH;
      } else if (bankId === "TACX") {
        window.location.href = POLICYPATH;
      } else if (bankId === "YHDB") {
        window.location.href = POLICYPATH;
      } else if (bankId === "ZTB") {
        window.location.href = POLICYPATH;
      } else if (bankId === "ZLTX") {
        window.location.href = POLICYPATH;
      } else if (bankId === "XDCX") {
        window.location.href = POLICYPATH;
      } else if (bankId === "SXDB") {
        window.location.href = POLICYPATH;
      } else if (bankId === "MXHDB") {
        window.location.href = POLICYPATH;
      }else if (bankId === "DUBON") {
        window.location.href = POLICYPATH;
      }else if (bankId === "PABLS") {
        window.location.href = POLICYPATH;
      }else if (bankId === "HFGK") {
        window.location.href = POLICYPATH;
      }else if (bankId === "JMDB") {
        window.location.href = POLICYPATH;
      }else if (bankId === "HSICBC") {
        window.location.href = POLICYPATH;
      }else if (bankId === "XTDB") {
        window.location.href = POLICYPATH;
      }else if (bankId === "XTRDB") {
        window.location.href = POLICYPATH;
      }else if (bankId === "NBXTRZDB") {
        window.location.href = POLICYPATH;
      }else if (bankId === "NRDDB") {
        window.location.href = POLICYPATH;
      }else if (bankId === "ZJLFGCDB") {
        window.location.href = POLICYPATH;
      }else if (bankId === "SCHYCFDB") {
        window.location.href = POLICYPATH;
      }else if (bankId === "TZZBRZDB") {
        window.location.href = POLICYPATH;
      }else if (bankId === "SCJXDB") {
        window.location.href = POLICYPATH;
      } else if (bankId === "EGBANK") {
        let res = await this.$getAPI.outherDownload({ processId });
        if (res.success == "1") {
          location.href = res.policyPath;
        } else {
          this.$alert(`保函尚未生成，银行正在处理中，请稍后再试`, "警告", {
            showConfirmButton: false,
            callback() {},
          });
        }
      } else {
        window.open(`${host}/personalCenter/showPdf.do?processId=${processId}`);
      }
    },
    fileSuccess(res) {
      if (!res.success) {
        this.$alert(res.msg, "警告", {
          showConfirmButton: false,
          callback() {},
        });
      } else {
        this.$alert("已成功上传支付凭证，请耐心等待处理结果", "提示", {
          showConfirmButton: false,
          callback() {},
        });
      }
    },
    fileError(res) {
      console.log(res);
    },
    getStepTitle(index, item) {
      return `${index + 1}.${item}`;
    },
    handleClose() {
      this.shenhetkVmFlag = false;
    },
    changeLatch(latch, bankId, data) {
      console.log(latch);
      this.stepActive = parseInt(latch) - 1;
      this.shenhetkVmFlag = data.SELF_FILLING_OF_NO == "2" ? false : true;
      if (latch == 3) {
        this.qipaoSrc = `https://www.cyc-fund.com.cn/image/guaranteeImages/${data.DICTCODE}.png`;
      } else {
        this.qipaoSrc = `https://www.cyc-fund.com.cn/image/bankImage/${bankId}.png`;
      }
    },
    async goApply(processId) {
      const res = await this.$getAPI.rePay({ processId });
      if (res.code === "01") {
        window.open(res.dataUrl);
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-radio {
  margin-top: 5px;
}
.myOrder {
  margin-left: 185px;
  &IconText.active {
    color: #e60012;
  }
  .paging {
    margin: 40px 20px;
  }
  &-search {
    margin-top: 20px;
    display: flex;
    align-items: center;
    &-item {
      display: flex;
      align-items: center;
      margin-left: 20px;
      border-radius: 8px;
      height: 32px;
      label {
        background-color: #ba1216;
        color: #fff;
        line-height: 32px;
        padding: 0 10px;
        border-radius: 8px 0 0 8px;
      }
      /deep/ .el-input__inner {
        height: 32px;
        line-height: 32px;
        border: 1px solid #959595;
        border-left: 0;
        overflow: hidden;
        border-radius: 0 8px 8px 0;
      }
      /deep/ .searchId {
        width: 130px;
      }
    }
    &-btn {
      display: inline-block;
      margin-left: 20px;
      #search {
        background-image: url(../assets/images/search.png);
        background-repeat: no-repeat;
        background-position: 7px 7px;
        padding-left: 36px;
        height: 40px;
        margin: 20px 0;
      }
      #reset {
        width: 80px;
        border: 1px #ba1216 solid;
        color: #ba1216;
        background-color: #fff;
        border-radius: 4px;
        margin-left: 20px;
      }
    }
  }
  .el-date-editor {
    width: 220px;
  }
  .row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    span {
      white-space: nowrap;
    }
    p {
      margin: 0;
    }
  }
  .btns {
    display: flex;
    flex-direction: column;
    text-align: center;
    a {
      width: 100px;
    }
  }
  .el-table__header {
    border-top: 2px solid #e60012;
  }
  .upload-demo {
    background: #008fff;
    border-radius: 4px;
    height: 32px;
    line-height: 32px;
    overflow: hidden;
    margin: 5px;
    a {
      position: relative;
      top: -5px;
    }
  }
  .shenhe {
    .qipao {
      margin-left: 29px;
    }
    .stepText {
      text-align: center;
      margin: 20px 29px 0;
      border: 1px solid rgba(23, 173, 65, 0.2);
      background: rgba(23, 173, 65, 0.04);
      padding: 20px;
      border-radius: 20px;
      font-weight: bold;
      font-size: 30px;
      color: rgba(0, 0, 0, 0.3);
    }
  }
  .closeOrderBox {
    h5 {
      font-size: 20px;
    }
    &-item {
      display: flex;
      margin-top: 10px;
      .left {
        margin-right: 10px;
      }
      .right {
        position: relative;
        b {
          color: #f00;
          font-size: 10px;
          position: absolute;
          bottom: -30px;
          left: 0;
        }
      }
    }
  }
  /deep/.el-rate__icon {
    margin: 0;
    font-size: 16px;
  }
  /deep/ .el-rate {
    text-align: left;
  }
}
</style>