// import {post,get,put,patch} from '@/utils/request'
import { get, post1, post ,put} from './request'
export default {
    indexData: params => get('/api/uc/personal/index.do', params), //首页信息
    indexInfoData: params => get('/api/uc/personal/recommendType.do', params), // 首页标讯信息
    recommendaTion: params => get('/api/uc/personal/recommendaTion.do', params), // 首页投标项目推荐瀑布流前六条
    indexBidData: params => get('/api/uc/personal/pubu.do', params), // 首页投标项目推荐瀑布流
    indexSwiper: params => post('/api/v2/adv/getAdvByKey?advkey=ucenter', params), // 移除收藏-标讯信息
    invoiceData: params => get('/api/uc/personal/queryBillList.do', params), //我的发票列表
    enterMessageData: params => get('/api/uc/personal/queryUser.do', params), //企业信息
    upEnterMessageData: params => get('/api/uc/personal/updateUser.do', params), //修改企业信息
    getMyOrder: params => get(`/api/uc/personal/myOrders.do?random=${Math.random()}`, params), //获取订单
    addCollection: params => get('/api/uc/personal/addCollection.do', params), //加入收藏
    showCollection: params => get('/api/uc/personal/collection.do', params), //查看收藏
    showBidCollection: params => get('/api/v2/getMyCollection', params), //查看收藏-标讯信息
    removeMyCollectionById: params => post('/api/v2/removeMyCollectionByIds', params), //移除收藏-标讯信息
    deleFocus: params => get('/api/uc/personal/deleFocus.do', params), //移除收藏
    toPay: params => get('/api/www/personalCenter/toPay.do',params), //查看支付信息 - 我的订单
    payment: params => get('/api/www/personalCenter/payment.do',params), //支付确认，支付取消 - 我的订单
    addInvoice: params => get('/api/www/personalCenter/exists.do', params), //查发票状态-开具发票和下载发票
    updateInvoice: params => get(`/api/www/user/register/update.do`, params), //状态是1-开具发票
    closeOrder: params => post1(`/api/www/personalCenter/invalid.do?processId=${params.processId}`, params), //关闭订单
    closeOrders: params => post1(`/api/www/personalCenter/invalids.do?processId=${params.processId}&status=${params.status}`, params), //关闭订单
    seachShenhe: params => get('/api/www/bidTenderLoans/seachShenhe.do', params), //我的订单-查看审核进度
    download: params => get('/api/www/personalCenter/query/download.do', params), //我的订单-下载保函
    outherDownload: params => get('/api/www/guaranteeEGBANKCtrl/guaranteeFileStreamQuery.do', params), //我的订单-EGBANK下载保函
    feedback: params => get('/api/www/userChat/feedBackAdd.do', params), //反馈
    switchAccount: params => get('/api/uc/personal/switchAccount.do', params), //获取账号信息
    judgeUser: params => get('/api/uc/personal/judgeUser.do', params), //获取用户类型
    changePwd: params => get('/api/uc/personal/changePwd.do', params), //修改密码
    registerCode: params => get('/api/uc/personal/registerCode.do', params), //获取验证码
    check: params => get('/api/uc/personal/check.do', params), //修改手机号
    reapply: params => get('/api/www/applyjinan/reset.do', params), //重新申请-我的订单
    getNoitce: params => get('/api/message/noticeapi/nc/deal_with', params), //查询消息通知
    delDot: params => post1('/api/message/noticeapi/uc/add_new_log', params), //删除消息未读标记
    getOrderShowDetail: params => post1('/api/order/statusLogInfo/searchDetailsById', params), //订单轮转-获取订单信息
    reBackApply: params => post1(`/api/uc/personalCenter/refund.do?processId=${params.processId}&status=${params.status}&message=${params.message}`, params), //我的订单-申请退保
    reBackCancal: params => post1(`/api/uc/personalCenter/cancelRefund.do?processId=${params.processId}&status=20&message=`, params), //我的订单-取消退保
    rePay: params => post1(`/api/uc/personalCenter/reapply.do?processid=${params.processId}`, params), //我的订单-重新下单
    putEvaluate: params => put('/api/message/order_assess', params), //我的订单-评价
    switchList: params => get('/api/switch/uc/v2/meberList', params), //切换账号
    getUserID: params => get('/api/switch/uc/v2/whoamI', params), //获取全局的userid
    getLoanRecordsList: params => post1('/api/uc/payReturn/loan/list.do', params), //获取中标贷列表
    applyInvoiceApi: params => post1('/api/uc/fapiao/apply.do', params), //申请发票

    // python接口
    outLogin: params => get('/api/switch/uc/v2/logOut', params), //退出登录
}