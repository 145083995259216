<template>
  <div class="evaluate">
    <el-dialog title="请对金融机构进行评价" :visible.sync="flag" width="30%">
      <div class="evaluate-body">
        <el-rate v-model="index"></el-rate>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="index = 0">重 置</el-button>
        <el-button type="primary" @click="close">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Evaluate",
  props: ["dialogVisible"],
  data() {
    return {
        index: 0,
      flag: false
    };
    },
    watch: {
        dialogVisible(v) {
            this.flag = v
        }
    },
    methods: {
      close() {
        if (this.index > 0) {
          // 当评价数据大于0的时候才做记录调接口
          const index = this.index
          this.index = 0
          this.$emit('close', index)
        } else {
          this.$emit('close', 0)
        }
        
    }
  }
};
</script>

<style lang="less" scoped>
.evaluate {
  &-body {
    width: 210px;
    margin: 0 auto;
  }
  /deep/ .el-rate__icon {
    font-size: 40px !important;
  }
  /deep/ .el-rate__text {
    font-size: 38px;
    margin-left: 10px;
  }
}
</style>