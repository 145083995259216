<template>
  <div class="switchId">
      <Menu></Menu>
      <div class="flex-1 content bor bgfff">
        <div class="public-title flex flex-pack-justify">
            <strong class="p_r">切换账号</strong>
        </div>
        <div class="switchId-table">
            <table v-if="userList && userList.length > 0">
                <tr>
                    <td>当前账号</td>
                    <td>{{userName}}</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>可切换账号</td>
                    <td>用户名</td>
                    <td>ID编号</td>
                    <td>注册时间</td>
                    <td>操作</td>
                    <td></td>
                </tr>
                <tr v-for="(item,index) in userList" :key="index">
                    <td></td>
                    <td>{{item.USERNAME}}</td>
                    <td>{{item.USERID}}</td>
                    <td>{{item.INSERTEDON}}</td>
                    <td>
                        <el-button type="primary" size="mini" @click="openCheckLogin(item.USERNAME)" v-if="item.channelNo != '02'" style="width:68px">切换</el-button>
                        <el-button size="mini" @click="openCheckCA" v-else>去登录</el-button>
                    </td>
                    <td><span v-if="item.channelNo == '02'">该账号仅支持CA登录</span></td>
                </tr>
            </table>
            <table v-else>
                    <tr>
                        <td>当前账号</td>
                        <td>{{userName}}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr >
                        <td colspan="4">
                            <div style="text-align:center">暂无其它账号</div>
                        </td>
                    </tr>
            </table>
        </div>
      </div>
  </div>
</template>

<script>
import Menu from '../components/Menu.vue'
export default {
    components: { Menu },
    name: 'switchId',
    data() {
        return  {
            userName: '',
            userList: []
        }
    },
    mounted() {
        this.getData()
        this.getList()
    },
    methods: {
        exitLogin(isCA, userName) {
            this.$cookies.remove('yc_tk','/', process.env.VUE_APP_COOKIE_BASE);
            this.$cookies.remove('uscn','/', process.env.VUE_APP_COOKIE_BASE);
            if (isCA) {
                location.href = `${process.env.VUE_APP_LOGIN_URL}/?returnUrl=${encodeURIComponent(location.href)}&type=ca`
            } else {
                location.href = `${process.env.VUE_APP_LOGIN_URL}/?returnUrl=${encodeURIComponent(location.href)}&userName=${userName}`
            }
        },
        openCheckLogin(userName) {
            this.$confirm('确定退出当前账号，跳转登录页切换账号吗？', '切换账号', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.exitLogin(false,userName)
            }).catch(() => {});
        },
        openCheckCA() {
            this.$confirm('确定退出当前账号，跳转登录页CA认证登录吗？', '切换账号', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.exitLogin(true)
            }).catch(() => {});
        },
        async getData() {
            let res = await this.$getAPI.switchAccount()
            this.userName = res.userNames;
            
        },
        async getList() {
            let res = await this.$getAPI.switchList()
            this.userList = res.data.result.resultData.orderList;
        }
    }
}
</script>

<style lang="less" scoped>
.switchId {
    margin-left: 185px;
    /deep/.el-button:focus {
        color: #606266;
        border-color: #dcdfe6;
        background-color: #fff;
    }
    /deep/.el-button:hover {
        color: #b10b18;
        border-color: #e8b6ba;
        background-color: #f7e7e8;
    }
    /deep/.el-button--primary:focus, /deep/.el-button--primary:hover {
        background: #c13c46;
        border-color: #c13c46;
        color: #fff;
    }
}
table{
    margin: 50px 100px;
    tr {
        td {
            height: 50px;
        }
        td:first-child {
            font-weight: bolder;
            text-align: right;
        }
        td:nth-child(2) {
            text-align: center;
            min-width: 180px;
        }
        td:nth-child(3) {
            text-align: center;
            min-width: 180px;
        }
        td:nth-child(4) {
            text-align: center;
            min-width: 200px;
        }
        td:nth-child(4) {
            text-align: center;
            padding: 0 10px;
        }
    }
    span {
        color: #b10b18;
    }
}
</style>