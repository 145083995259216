import { render, staticRenderFns } from "./upPhone.vue?vue&type=template&id=182a082f&scoped=true&"
import script from "./upPhone.vue?vue&type=script&lang=js&"
export * from "./upPhone.vue?vue&type=script&lang=js&"
import style0 from "./upPhone.vue?vue&type=style&index=0&id=182a082f&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "182a082f",
  null
  
)

export default component.exports