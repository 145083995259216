<template>
  <div class="loanRecords">
    <Menu></Menu>
    <div class="flex-1 content bor bgfff loanRecords-container">
      <div class="order" style="position:relative;">
        <div class="tabs flex">
          <span class="p_r" :class="{active:tabIndex === 0}" @click="tabIndex=0">申请进度</span>
          <span class="p_r" :class="{active:tabIndex === 1}" @click="tabIndex=1">还款计划</span>
        </div>
      </div>
      <div class="loanRecords-search" v-show="tabIndex==0">
        <div class="loanRecords-search-item">
          <label>项目名称</label>
          <el-input class="searchId" v-model="projectName" placeholder="" size="medium"></el-input>
        </div>
        <div class="loanRecords-search-item">
          <label>申请日期</label>
          <el-date-picker v-model="applyDate" type="daterange" range-separator="-" size="small" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div class="loanRecords-search-btn">
          <el-button type="primary" @click="searchGetOrderList">查询</el-button>
          <el-button id="reset" @click="clear">重置</el-button>
        </div>
      </div>
      <div class="loanRecords-box" v-if="tabIndex==0">
        <el-table empty-text="无内容" height="400" :data="tableData" border width="100%">
          <el-table-column label="申请编号" prop="processId"></el-table-column>
          <el-table-column label="项目名称" prop="projectName"></el-table-column>
          <el-table-column label="中标公告" width="120">
            <template slot-scope="scope">
              <a :href="scope.row.loanUrl" target="_blank" class="link">《中标公告》</a>
            </template>
          </el-table-column>
          <el-table-column label="企业名称" prop="companyName"></el-table-column>
          <el-table-column label="企业注册地" prop="companyAddress"></el-table-column>
          <el-table-column label="中标金额（元）" prop="bidedAmt"></el-table-column>
          <el-table-column label="贷款金额（元）" prop="loanAmt"></el-table-column>
          <el-table-column label="申请人/法人" prop="applyName"></el-table-column>
          <el-table-column label="手机号" prop="applyTel"></el-table-column>
          <el-table-column label="申请时间" prop="applyTime"></el-table-column>
        </el-table>
        <!-- 分页开始 -->
        <div class="paging">
          <el-pagination background layout="prev, pager, next, sizes,jumper,slot" :page-sizes="[10, 20, 30, 50, 100]" :page-size="count" :total="total" prev-text="上一页" next-text="下一页" @size-change="upCountPage" @current-change="upPage">
            <el-button size="small" class="pagebtn" style="
              border: 1px solid #dcdcdc;
              box-shadow: 0px 2px 4px 0px rgb(0 117 230 / 77%);
              border-radius: 4px;
              background: #fff;
            ">确定</el-button>
          </el-pagination>
        </div>
      </div>
      <div v-show="tabIndex==1" class="loanRecords-plan">
        <el-empty description="暂未开通，敬请期待"></el-empty>
      </div>
      
    </div>
    
  </div>
</template>

<script>
import Menu from "../components/Menu.vue";
export default {
  components: { Menu },
  data() {
    return {
      count: 10,
      total: 0,
      pageNum: 1,
      tabIndex: 0,
      projectName: "",
      applyDate: "",
      tableData: [],
    };
  },
  created() {
    this.searchGetOrderList()
  },
  methods: {
    async searchGetOrderList() {
      const res = await this.$getAPI.getLoanRecordsList({
        projectName: this.projectName,
        applyDateStart: this.formatDate(this.applyDate[0]),
        applyDateEnd: this.formatDate(this.applyDate[1]),
        pageSize: this.count,
        pageNum: this.pageNum
      })
      this.tableData = res.data.list;
      this.total = res.data.total;
    },
    clear() {
      this.projectName = ""
      this.applyDate = ""
    },
    upCountPage(n) {
      this.count = n;
      this.pageNum = 1;
      this.searchGetOrderList()
    },
    upPage(n) {
      this.pageNum = n;
      this.searchGetOrderList()
    },
    add0(n) {
      return n > 9 ? n : `0${n}`;
    },
    formatDate(n) {
      if (!n) return "";
      const YYYY = new Date(n).getFullYear();
      const MM = new Date(n).getMonth() + 1;
      const DD = new Date(n).getDate();
      const h = new Date(n).getHours();
      const m = new Date(n).getMinutes();
      const s = new Date(n).getSeconds();
      return `${YYYY}-${this.add0(MM)}-${this.add0(DD)} ${this.add0(
        h
      )}:${this.add0(m)}:${this.add0(s)}`;
    },
    
  },
};
</script>

<style scoped lang="less">
.loanRecords {
  margin-left: 185px;
  &-container {
    position: relative;
  }
  &-box {
    padding: 0 20px;
    width: 100%;
  }
  &-search {
    margin-top: 20px;
    display: flex;
    align-items: center;
    &-item {
      display: flex;
      align-items: center;
      margin-left: 20px;
      border-radius: 8px;
      height: 32px;
      label {
        background-color: #ba1216;
        color: #fff;
        line-height: 32px;
        padding: 0 10px;
        border-radius: 8px 0 0 8px;
      }
      /deep/ .el-input__inner {
        height: 32px;
        line-height: 32px;
        border: 1px solid #959595;
        border-left: 0;
        overflow: hidden;
        border-radius: 0 8px 8px 0;
      }
      /deep/ .searchId {
        width: 130px;
      }
    }
    &-btn {
      display: inline-block;
      margin-left: 20px;
      #search {
        background-image: url(../assets/images/search.png);
        background-repeat: no-repeat;
        background-position: 7px 7px;
        padding-left: 36px;
        height: 40px;
        margin: 20px 0;
      }
      #reset {
        width: 80px;
        border: 1px #ba1216 solid;
        color: #ba1216;
        background-color: #fff;
        border-radius: 4px;
        margin-left: 20px;
      }
    }
  }
  &-plan {
    width: 200px;
    text-align: center;
    margin: 10px auto;
  }
  
  
  /deep/.el-table {
    margin-top: 20px;
    th {
      background-color: #f5f5f5;
      border-bottom: 1px solid #ebeef5;
      border-right: 1px #ebeef5 solid;
      &:last-child {
        border-right: 0;
      }
    }
    td {
      border-bottom: 1px solid #ebeef5;
      border-right: 1px #ebeef5 solid;
      .link {
        color:#e60012;
      }
    }
  }
  /deep/.el-table__header {
    border-top: 2px solid #e60012;
  }
}
</style>