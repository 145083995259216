import axios from 'axios';
var COS = require("cos-js-sdk-v5");
export default function upload(res,ispdf) {
  if (res) {
    var cos = new COS({
      // 必选参数
      getAuthorization: function (options, callback) {
        axios.get('/uploadsts').then(res => {
          var data = res.data
          var credentials = data && data.credentials;
          if (!data || !credentials) return console.error('credentials invalid');
          callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              SecurityToken: credentials.sessionToken,
              // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
              StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
              ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
          });
        })
      }
    });
    // var cos = new COS({
    //     SecretId: 'AKIDta091cx0mHVS9ZiaBVZLq53kAKCo8ByU',
    //     SecretKey: 'GFzTgcHflb9RSCftqWwVv4wXruf9NJyM',
    // });
    return new Promise((resolve, reject) => {
      // 执行上传操作
    cos.putObject(
      {
        Bucket: "beijingv2-1254376814" /* 存储桶 */,
        Region: "ap-beijing" /* 存储桶所在地域，必须字段 */,
        Key: res.name /* 文件名 */,
        StorageClass: "STANDARD", // 上传模式, 标准模式
        Body: ispdf ? res.file : res.raw, // 上传文件对象
        ContentType: "",
        onProgress: (progressData) => {
          // 上传进度
          console.log(JSON.stringify(progressData));
        },
      },
      (err, data) => {
        console.log(err || data);
        // 上传成功之后
        if (data && data.statusCode === 200) {
          resolve({
            filePath: `https://${data.Location}`
          });
        } else {
          reject(err || data)
        }
      }
    );
    })
  }
}
