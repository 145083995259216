export default {
  370100: "Jinan",
  131000: "Langfang",
  511300: "Nanchong",
  441900: "Dongguan",
  530100: "Kunming",
  330600: "Shaoxing",
  330200: "Ningbo",
  610100: "Xian",
  340800: "Anqing",
  510600: "Deyang",
  321200: "Taizhou321200",
  430200: "Zhuzhou",
  130682: "Dingzhou",
  321100: "Zhenjiang",
  371300: "Linyi",
  130200: "Tangshan",
  220100: "Changchun",
  130600: "Baoding",
  440400: "Zhuhai",
  350100: "Fuzhou",
  533100: "Dehong",
  500000: "Chongqing",
  530900: "Lincang",
  532800: "Xishuangbanna",
  530500: "Baoshan",
  341200: "Fuyang",
  150200: "Baotou",
  410100: "Zhengzhou",
  320400: "Changzhou",
  110000: "Beijing",
  370800: "Jining",
  330700: "Jinhua",
  330300: "Wenzhou",
  331000: "Taizhou331000",
  340100: "Hefei",
  210200: "Dalian",
  340500: "Maanshan",
  130181: "Xinji",
  130100: "Shijiazhuang",
  430100: "Changsha",
  510100: "Chengdu",
  130500: "Xingtai",
  440700: "Jiangmen",
  130900: "Cangzhou",
  440300: "Shenzhen",
  331100: "Lishui",
  532900: "Dali",
  530600: "Zhaotong",
  320900: "Yancheng",
  532500: "Honghe",
  320500: "Suzhou",
  150100: "Huhehaote",
  320100: "Nanjing",
  450100: "Nanning",
  620100: "Lanzhou",
  330800: "Quzhou",
  511100: "Leshan",
  460100: "Haikou",
  330400: "Jiaxing",
  442000: "Zhongshan",
  310000: "Shanghai",
  370700: "Weifang",
  610300: "Baoji",
  520100: "Guiyang",
  340200: "Wuhu",
  210100: "Shenyang",
  130400: "Handan",
  130800: "Chengde",
  440600: "Foshan",
  533300: "Nujiang",
  420100: "Wuhan",
  360100: "Nanchang",
  341800: "Xuancheng",
  530700: "Lijiang",
  230100: "Haerbin",
  532600: "Wenshan",
  530300: "Qujing",
  320200: "Wuxi",
  131100: "Hengshui",
  370200: "Qingdao",
  410300: "Luoyang",
  330900: "Zhoushan",
  532300: "Chuxiong",
  330500: "Huzhou",
  330100: "Hangzhou",
  370600: "Yantai",
  460200: "Sanya",
  610400: "Xianyang",
  120000: "Tianjin",
  510700: "Mianyang",
  340300: "Bengbu",
  350600: "Zhangzhou",
  321000: "Yangzhou",
  371000: "Weihai",
  130300: "Qinhuangdao",
  533400: "Deqen",
  440500: "Shantou",
  220200: "Jilin",
  130700: "Zhangjiakou",
  350200: "Xiamen",
  440100: "Guangzhou",
  530800: "Puer",
  140100: "Taiyuan",
  530400: "Yuxi",
  450300: "Guilin",
  341100: "Chuzhou",
  320300: "Xuzhou",
  341500: "Liuan",
};
