import pdfjsLib from "pdfjs-dist"
export default function pdfToBase64(url) {
    return new Promise((resolve) => {
        console.log(url)
        pdfjsLib.getDocument(url).then(function getPdfHelloWorld(pdf) {
            pdf.getPage(1).then(function getPageHelloWorld(page) {
                var scale = 1;
                var viewport = page.getViewport(scale);
                var canvas = document.getElementById('the-canvas');
                var context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                var renderContext = {
                    canvasContext: context,
                    viewport: viewport
                };
                page.render(renderContext);
                setTimeout(() => {
                    resolve(convertCanvasToImage())
                }, 100);
            });
        });
    })
}
function convertCanvasToImage() {
    var canvas = document.getElementById('the-canvas');
    return canvas.toDataURL("image/png")
}