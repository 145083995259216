<template>
  <div>
    <div class="footer">
      <div class="footer_cont">
        <div class="footer_cont_top">
          <div class="footTwo">
            <p class="footTwo_tel">400-690-6108</p>
            <p class="footTwo_time" style="margin-top: 5px">
              服务热线:(上午09:00-12:00--下午13:00-18:30)
            </p>
            <div class="center_kefu" @click="goKefu">
              <div class="kefuBtn">
                <a href="">
                  <p class="kefu">
                    <!-- <span class="btn_img">
                      <img src="../assets/images/qq.png" alt=""
                    /></span> -->
                    咨询在线客服
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="footer_cont_bottom">
          <div class="bot_p">
            <p>
              Copyright©2017-2023,www.zhonghongwang.com. All rights reserved. | 
              ICP证：<span @click="goICP">京ICP备15059884号-1</span> 北京云采科技有限公司提供技术支持
            </p>
          </div>
        </div>
      </div>
      <div class="footer_show">
        <div class="footer_show_bottom">
          <img class="imgOne" src="../assets/images/china.png" alt="" />
          <img
            class="imgTwo"
            src="../assets/images/education.png"
            alt=""
          />
          <img
            class="imgThree"
            src="../assets/images/security.png"
            alt=""
          />
          <img
            class="imgFour"
            src="../assets/images/Zhongguancun.png"
            alt=""
          />
          <img class="imgFive" src="../assets/images/tech.png" alt="" />
          <img
            class="imgSix"
            src="../assets/images/police.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footers",
  props: {
    imgSrc: {
      type: Array,
      defaulut() {
        return [];
      },
    },
    poster: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      host: '',
      url: [],
      friendshipUrl: [
        {name: '丽水市公共资源交易中心', href: 'https://lssggzy.lishui.gov.cn/'},
        {name: '杭州市公共资源交易中心', href: 'https://ggzy.hzctc.hangzhou.gov.cn/Index/Home'},
        {name: '宁波市公共资源交易中心', href: 'http://www.nbggzy.cn/'},
        {name: '济南市公共资源交易中心', href: 'http://jnggzy.jinan.gov.cn/'}
      ]
    };
  },
  mounted() {
    this.host = process.env.VUE_APP_BID_URL
    this.url = [
      {name: '企业合作', href: `${this.host}/Enterprise`},
      {name: '地方政府合作', href: `${this.host}/Local`},
      {name: '金融机构合作', href: `${this.host}/Finance`},
    ]
  },
  methods: {
    goKefu() {
      window.open(`${process.env.VUE_APP_BASE_URL}/userChat/goUserChat.do`)
    },
    goICP() {
      window.open('https://beian.miit.gov.cn/')
    }
  }
};
</script>


<style>
.footer {
  width: 100%;
  margin-top: 50px;
}

.footer_cont {
  width: 100%;
  /* height: 297px; */
  background-color: #363c41;
}

.footer_cont_top {
  width: 1212px;
  /* height: 231px; */
  margin: 0 auto;
  background-color: #363c41;
}

.footer_cont_bottom {
  width: 100%;
  height: 65px;
  border-top: 1px solid #666;
  background-color: #363c41;
  margin-top: 24px;
}

.bot_p {
  width: 1212px;
  height: 100%;
  margin: 0 auto;
  background-color: #363c41;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer_cont_bottom p {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #a0a1a1;
}

/*  */

.footer_show {
  width: 100%;
  /* height: 50px; */
}

.footer_show_bottom {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footer_show_bottom {
  width: 1212px;
  margin: 0 auto;
  margin-top: 6px;
}

.imgOne,
.imgTwo,
.imgThree,
.imgFour,
.imgFive,
.imgSix {
  height: 66px;
}

.imgOne {
  width: 378px;
}

.imgTwo {
  width: 215px;
}

.imgThree {
  width: 167px;
}

.imgFour {
  width: 183px;
}

.imgFive {
  width: 136px;
}

.imgSix {
  width: 167px;
}

.footer_cont_top {
  display: flex;
  justify-content: space-between;
}

.foot {
  display: flex;
  justify-content: space-between;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #d6d8d9;
  /* line-height: 14px; */
}


.footTwo {
  margin: 0 auto;
  margin-top: 80px;
}

.footThree p {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
}

.foot h2 {
  font-size: 16px;
  color: #d6d8d9;
}

.foot a {
  color: #d6d8d9;
}

.footTwo {
  text-align: center;
}

.footThree {
  display: flex;
  justify-content: space-between;
}

.footThree_codeOne {
  display: inline-block;
  width: 119px;
  height: 118px;
}

.footThree_codeOne img {
  width: 100%;
  height: 100%;
}

.footThree_codeTwo {
  display: inline-block;
  width: 119px;
  height: 118px;
}

.footThree_codeTwo img {
  width: 100%;
  height: 100%;
}

.footTwo_tel {
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #a0a1a1;
  margin: 0;
}

.footTwo_time {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #a0a1a1;
}

.kefuBtn {
  width: 140px;
  height: 32px;
  background: #fd5f39;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.kefu {
  margin: 0;
  display: inline-block;
  width: 140px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
}

.btn_img {
  display: inline-block;
  width: 16px;
  height: 18px;
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translate(0%, -50%);
}

.btn_img img {
  width: 100%;
  height: 100%;
}

.center_kefu {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>

