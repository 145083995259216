import Vue from 'vue'
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import './theme/index.css'
import App from './App.vue'
import axios from 'axios';
import md5 from 'js-md5'
import VueAxios from 'vue-axios';
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'
import routerConfig from './router'
import less from 'less'
import getAPI from './utils/js/api'

Vue.config.productionTip = false

Vue.use(VueAxios, axios);
Vue.use(VueRouter)
Vue.use(less)
Vue.use(ElementUI)
Vue.use(VueCookies)

Vue.prototype.$md5 = md5;
Vue.prototype.$axios = axios;
Vue.prototype.$getAPI = getAPI;

const router = new VueRouter(routerConfig)


// router.beforeEach((to, from, next) => {
//    if (to.path) {
//     window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
//    }
//   next();
//  });

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')



